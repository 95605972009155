
import { Vue, Component, Prop } from "vue-property-decorator";
import MarginBase from "@/types/margin-base";

@Component
export default class MarginBaseDeleteModal extends Vue {
  @Prop({ default: false }) readonly show!: boolean;
  @Prop({ default: null }) readonly index!: number;
  @Prop({ default: {} }) readonly item!: MarginBase;

  constructor() {
    super();
  }
}
