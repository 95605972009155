export default interface PaymentStatus {
  id: number;

  name: string;
  readableName: string;

  created_at: Date;
  updated_at: Date;
  deleted_at?: Date | null;
}

const openStatus: Pick<PaymentStatus, "id" | "name" | "readableName"> = {
  id: 1,
  name: "EM_ABERTO",
  readableName: "Em aberto"
};

const paidStatus: Pick<PaymentStatus, "id" | "name" | "readableName"> = {
  id: 2,
  name: "PAGO",
  readableName: "Pago"
};

const paidPartiallyStatus: Pick<PaymentStatus, "id" | "name" | "readableName"> =
  {
    id: 3,
    name: "PAGO_PARCIALMENTE",
    readableName: "Pago parcialmente"
  };

const receivedStatus: Pick<PaymentStatus, "id" | "name" | "readableName"> = {
  id: 4,
  name: "BAIXADO",
  readableName: "Baixado"
};

const waiting: Pick<PaymentStatus, "id" | "name" | "readableName"> = {
  id: 5,
  name: "AGUARDANDO_GERACAO",
  readableName: "Aguardando Geração"
};


const unlinked_from_refin: Pick<PaymentStatus, "id" | "name" | "readableName"> = {
  id: 6,
  name: "DESVINCULADO_REFINANCIAMENTO",
  readableName: "Desvinculado Refinanciamento"
};

export const PaymentStatusEnum = {
  OPEN: openStatus,
  PAID: paidStatus,
  // PAID_PARTIALLY: paidPartially,
  RECEIVED: receivedStatus,
  WAITING: waiting,
  UNLINKED_FROM_REFIN: unlinked_from_refin

};

export function getPaymentStatusReadableNameById(
  paymentStatusId: number
): string {
  const statusIdToReadableName = Object.fromEntries(
    Object.values(PaymentStatusEnum).map((status) => [
      status.id,
      status.readableName
    ])
  );
  return statusIdToReadableName[paymentStatusId] || "";
}
