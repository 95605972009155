export interface DebtAgreementInstallmentsStatus {
  id: number;
  name: string;
  description: string;
}

const pendingStatus: DebtAgreementInstallmentsStatus =
  {} as DebtAgreementInstallmentsStatus;
pendingStatus.id = 1;
pendingStatus.name = "PENDENTE";
pendingStatus.description = "Pendente ";

const paidStatus: DebtAgreementInstallmentsStatus =
  {} as DebtAgreementInstallmentsStatus;
paidStatus.id = 2;
paidStatus.name = "PAGA";
paidStatus.description = "Paga";

const generatedStatus: DebtAgreementInstallmentsStatus =
  {} as DebtAgreementInstallmentsStatus;
generatedStatus.id = 4;
generatedStatus.name = "GERADA";
generatedStatus.description = "Gerada";

const canceledStatus: DebtAgreementInstallmentsStatus =
  {} as DebtAgreementInstallmentsStatus;
canceledStatus.id = 3;
canceledStatus.name = "CANCELADA";
canceledStatus.description = "Cancelada";

export const DebtAgreementInstallmentStatusEnum = {
  PENDING: pendingStatus,
  PAID: paidStatus,
  GENERATED: generatedStatus,
  CANCELED: canceledStatus
};
