
import ChargeManageModal from "@/components/charges/ChargeManageModal.vue";
import ChargeAddModal from "@/components/charges/ChargeAddModal.vue";
import Page from "@/components/core/dashboard/Page.vue";
import DatePicker from "@/components/DatePicker.vue";
import Modal from "@/components/Modal.vue";
import dayjs from "@/plugins/day-js";
import LoanService from "@/services/loan-service";
import ListPaymentsParams from "@/services/payment-service/types/list-payments-params";
import ListPaymentsResponse from "@/services/payment-service/types/list-payments-response";
import Payment from "@/types/payment";
import PaymentOrigin, { PaymentOriginEnum } from "@/types/payment-origin";
import PaymentStatus, { PaymentStatusEnum } from "@/types/payment-status";
import PaymentType from "@/types/payment-type";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { Component, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import PayerType from "@/types/payer-type";
import formatCurrency from "@/utils/formatCurrency";
import { getBankSlipCurrentStatus } from "@/utils/bankslip";
import PaymentService from "@/services/payment-service";
import { exportToSpreadsheet } from "../utils/exportToSpreadsheet";

interface ChargesFilters {
  page: number;
  limit: number;
  sort: string;
  payer?: any;
  paymentTypeId?: number;
  paymentStatusId?: number;
  paymentOriginId?: number;
  ourNumber?: string;
  yourNumber?: string;
  dateTypeId?: number;
  initialDate?: string;
  endDate?: string;
  bankSlipStatus?: string;
  excludeWaitingPayments?: boolean;
  excludeUnlinkedPayments?: boolean;
}

@Component({
  components: {
    ChargeManageModal,
    ChargeAddModal,
    Modal,
    Page,
    DatePicker
  }
})
export default class Charges extends Vue {
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  service: LoanService;
  paymentService: PaymentService;
  payments: ListPaymentsResponse;
  filters: ChargesFilters;
  headers: Array<DataTableHeader>;
  editCharge: Payment | null = null;
  chargeAddModal: boolean;
  loading = false;
  options: DataOptions;

  dateTypes = [
    {
      value: 1,
      text: "Data de emissão"
    },
    {
      value: 2,
      text: "Data de vencimento"
    },
    {
      value: 3,
      text: "Data de pagamento"
    }
  ];

  bankSlipStatusList = [
    { id: "RECEIVED", description: "Baixado" },
    { id: "PAID", description: "Liquidado" },
    { id: "OPEN", description: "Em Aberto" },
    { id: "EXPIRED", description: "Vencido" }
  ];
  paymentOrigins: PaymentOrigin[];
  paymentTypes: PaymentType[];
  paymentStatus: PaymentStatus[];
  PaymentStatusEnum = PaymentStatusEnum;
  PaymentOriginEnum = PaymentOriginEnum;
  formatCurrency = formatCurrency;
  getBankSlipCurrentStatus = getBankSlipCurrentStatus;

  constructor() {
    super();
    this.service = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();

    this.headers = [
      { text: "Pagador", value: "payer.name", sortable: true },
      { text: "Valor", value: "amount", sortable: true },
      {
        text: "Data de emissão",
        value: "p.created_at",
        sortable: true
      },
      {
        text: "Data de vencimento",
        value: "dueDate",
        sortable: true
      },
      {
        text: "Data de pagamento",
        value: "p.paidDate",
        sortable: true
      },
      { text: "Origem", value: "origin.id", sortable: true },
      { text: "Tipo", value: "type.id", sortable: true },
      {
        text: "Status",
        value: "status.id",
        sortable: true
      },
      {
        text: "Situação boleto",
        value: "bankSlip.status",
        sortable: false
      },
      {
        text: "Nosso número",
        value: "bankSlip.ourNumber",
        sortable: false
      },
      {
        text: "Seu número",
        value: "bankSlip.yourNumber",
        sortable: false
      },
      {
        text: "Ações",
        value: "actions",
        sortable: false,
        cellClass: "text-end",
        class: "text-end"
      }
    ];
    this.options = {} as DataOptions;
    this.filters = {
      page: 1,
      limit: 10,
      sort: "payer.name:DESC"
    };
    this.payments = {
      items: [],
      total: 0
    };
    this.paymentOrigins = [];
    this.paymentTypes = [];
    this.paymentStatus = [];
    this.chargeAddModal = false;
  }

  mounted() {
    this.fetchPaymentOrigins();
    this.fetchPaymentTypes();
    this.fetchPaymentStatus();
  }

  @Watch("options")
  onOptionsChange(tableOptions: DataOptions): DataOptions {
    this.filters.paymentStatusId != PaymentStatusEnum.WAITING.id
      ? (this.filters.excludeWaitingPayments = true)
      : (this.filters.excludeWaitingPayments = false);
    this.filters.paymentStatusId != PaymentStatusEnum.UNLINKED_FROM_REFIN.id
      ? (this.filters.excludeUnlinkedPayments = true)
      : (this.filters.excludeUnlinkedPayments = false);
    this.filters.page = tableOptions.page;
    this.filters.limit = tableOptions.itemsPerPage;
    this.filters.sort = this.formatSort(
      tableOptions.sortBy,
      tableOptions.sortDesc
    );
    this.fetchPayments();

    return tableOptions;
  }

  applyFilter() {
    this.onOptionsChange({ ...this.options, page: 1 });
  }

  sanitizeFilters(filters: ListPaymentsParams) {
    if (filters.initialDate == "") delete filters.initialDate;
    if (filters.endDate == "") delete filters.endDate;
    if (filters.payer == "") delete filters.payer;
    if (filters.ourNumber == "") delete filters.ourNumber;
    if (filters.yourNumber == "") delete filters.yourNumber;

    return filters;
  }

  dateTypeChanged() {
    if (!this.filters.dateTypeId) {
      this.filters.initialDate = "";
      this.filters.endDate = "";
    }
  }

  async fetchPayments() {
    this.loading = true;
    const [error, payments] = await this.paymentService.listAllPayments(
      this.sanitizeFilters(this.filters)
    );

    if (!error) {
      this.payments = payments!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;
  }

  async fetchPaymentOrigins(): Promise<PaymentOrigin[]> {
    this.loading = true;
    const [error, paymentOrigins] =
      await this.paymentService.listPaymentOrigins();

    if (!error) {
      this.paymentOrigins = paymentOrigins;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;

    return this.paymentOrigins;
  }

  async fetchPaymentTypes(): Promise<PayerType[]> {
    this.loading = true;
    const [error, paymentTypes] = await this.paymentService.listPaymentTypes();

    if (!error) {
      this.paymentTypes = paymentTypes!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;

    return this.paymentTypes;
  }

  async fetchPaymentStatus(): Promise<PaymentStatus[]> {
    this.loading = true;
    const [error, paymentStatus] =
      await this.paymentService.listPaymentStatus();

    if (!error) {
      this.paymentStatus = paymentStatus!;
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loading = false;

    return this.paymentStatus;
  }

  downloadExcel(): void {
    const sheet = [
      [
        "Pagador",
        "Inscrição",
        "Valor",
        "Valor pago",
        "Data de emissão",
        "Data de vencimento",
        "Data de pagamento",
        "Origem",
        "Tipo",
        "Status",
        "Situação boleto",
        "Nosso número",
        "Seu número"
      ]
    ];

    this.payments.items.forEach((item) => {
      sheet.push([
        item.payer?.name || "",
        item.payer?.document || "",
        this.formatCurrency(
          item.paymentBankSlip?.amount || item.paymentOthers?.amount || 0
        ),
        this.formatCurrency(item.paidValue || 0),
        this.formatDate(item.created_at),
        this.formatDate(
          item.paymentBankSlip?.dueDate || item.paymentOthers?.dueDate
        ),
        item.paidDate ? this.formatDate(item.paidDate) : "-",
        `${item.origin.description}${
          item.origin.name === "LOTE" ? " " + item.paymentBatch?.batchId : ""
        }`,
        item.type.name,
        item.status.readableName,
        getBankSlipCurrentStatus({
          bankSlipDueDate: item.paymentBankSlip?.dueDate,
          paymentStatus: item.status
        }),
        item.paymentBankSlip?.ourNumber,
        item.paymentBankSlip?.yourNumber
      ]);
    });
    exportToSpreadsheet({
      data: sheet,
      fileName: "gooroo-cobrancas-" + dayjs().format("YYYY-MM-DD-HH-mm-ss")
    });
  }

  formatSort(sortBy: Array<string>, sortDesc: Array<boolean>): string {
    const attr = sortBy[0] ?? "p.id";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return `${attr}:${order}`;
  }

  formatDate(date: string) {
    if (!date) return "-";
    return dayjs(date).format("DD/MM/YYYY");
  }

  closeModal() {
    this.editCharge = null;
    this.chargeAddModal = false;
  }

  get isAdminGooroo() {
    return (
      this.$store.getters["auth/authenticatedUser"]?.type == "ADMIN_GOOROO"
    );
  }
}
