
import Modal from "@/components/Modal.vue";
import dayjs from "dayjs";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import DebtAgreementService from "../../services/debt-agreement-service";
import { DebtAgreement } from "../../types/debt-agreement";
import { DebtAgreementInstallmentStatusEnum } from "../../types/debt-agreement-installment-status";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";

@Component({
  components: {
    ValidationObserver,
    Modal
  }
})
export default class DebtAgreementCancelModal extends Vue {
  @Prop() item!: DebtAgreement;

  DebtAgreementInstallmentStatusEnum = DebtAgreementInstallmentStatusEnum;

  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();

  detailedItem: DebtAgreement = { ...this.item };
  loadingData: boolean = false;
  loadingCancel: boolean = false;

  async mounted(): Promise<void> {
    this.loadingData = true;

    const [error, response] =
      await this.debtAgreementService.findDebtAgreementById(this.item.id);

    if (error) {
      this.$notify({
        title: "Acordo",
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.detailedItem = response!;
    }

    this.loadingData = false;
  }

  async cancel(): Promise<void> {
    this.loadingCancel = true;

    const [error, response] =
      await this.debtAgreementService.cancelDebtAgreement(this.item.id);

    if (error) {
      this.$notify({
        title: "Acordo",
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.$notify({
        title: "Acordo",
        type: "success",
        text: "Acordo cancelado com sucesso"
      });

      this.close();
      this.refresh();
    }

    this.loadingCancel = false;
  }

  close(): void {
    this.$emit("close");
  }

  refresh(): void {
    this.$emit("refresh");
  }

  get formattedEffectiveDate(): string {
    return this.detailedItem.effectiveDate
      ? dayjs(this.detailedItem.effectiveDate).format("DD/MM/YYYY")
      : "-";
  }

  get paidInstallmentsCount(): number {
    return (this.detailedItem.installments || []).filter(
      (installment) =>
        installment.statusId === DebtAgreementInstallmentStatusEnum.PAID.id
    ).length;
  }
}
