/* eslint-disable no-useless-escape */
/** Checks if a password is valid.
 *
 * Rules:
 * - At least 8 characters
 * - At least 1 letter
 * - At least 1 number
 * - At least 1 special character
 * - Maximum of 255 characters
 * @param password The password to be checked
 * @returns true if the password is valid, false otherwise
 * @example
 * isValidPassword('Test123!')    // true (meets all criteria)
 * isValidPassword('short1!');    // false (too short)
 * isValidPassword('NoNumbers!'); // false (no numbers)
 * isValidPassword('12345678!');  // false (no letters)
 * isValidPassword('12345678a');  // false (no special characters)
 */
export const isValidPassword = (password: string): boolean => {
  const pattern =
    /^(?=.*[A-Za-z])(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]).{8,}$/;
  return password.length <= 255 && pattern.test(password);
};

export const INVALID_PASSWORD_DEFAULT_MESSAGE =
  "A senha deve ter pelo menos 8 caracteres (dentre letras, números e caracteres especiais) e deve ser diferente das últimas 3 senhas.";

export const INVALID_PASSWORD_FOR_NEW_MEMBERS_DEFAULT_MESSAGE =
  "A senha deve ter pelo menos 8 caracteres (dentre letras, números e caracteres especiais).";
