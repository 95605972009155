import { AxiosError } from "axios";

export default function getErrorMessageFromApiError(
  apiError: AxiosError
): string {
  if (apiError.response) {
    if (
      apiError.response.status === 422 &&
      (apiError.response.data as any)?.code == "ValidationErrorException" &&
      (apiError.response.data as any)?.message?.length > 0 &&
      (apiError.response.data as any).message[0].message &&
      (apiError.response.data as any).message[0].message.length
    ) {
      return (apiError.response.data as any).message[0].message[0];
    }
    // The request was made and the server responded with a status code
    // that falls out of the range of 2xx
    // Do nothing for now (error will be displayed next)
  } else if (apiError.request) {
    // The request was made but no response was received
    // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
    // http.ClientRequest in node.js
    return "Não foi possível processar a sua requisição. Tente novamente mais tarde.";
  } else {
    // Something happened in setting up the request that triggered an Error
    return "Ocorreu um erro inesperado. Tente novamente mais tarde.";
  }

  const errors = apiError.response?.data;

  if (Array.isArray(errors)) {
    return errors[0].message;
  } else {
    const error = errors as any;
    return error.message;
  }
}
