type NotationType =
  | "standard"
  | "scientific"
  | "engineering"
  | "compact"
  | undefined;
export default function formatCurrency(
  value: number,
  notation?: NotationType
): string {
  const formatter = new Intl.NumberFormat("pt-BR", {
    style: "currency",
    currency: "BRL",
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    notation
  });

  return formatter.format(value);
}
