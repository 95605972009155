/**
 * Checks if the input string follows a valid pattern for a checking account.
 * Valid patterns:
 * - Exactly 4 digits.
 * - More than 4 digits, with a dash and at least one digit after the dash.
 */
export default function isValidCheckingAccount(value: string): boolean {
  const pattern = /^(\d{4}|\d{4,11}-\d{1})$/;

  return pattern.test(value);
}
