import Holidays, { HolidaysTypes } from "date-holidays";
import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
dayjs.extend(isSameOrBefore);
const workingWeekDays = [1, 2, 3, 4, 5];
const allowedHollidayTypes = [
  "public",
  "optional",
  "bank"
] as HolidaysTypes.HolidayType[];
const holidays = new Holidays("BR", {
  timezone: "America/Sao_Paulo",
  types: allowedHollidayTypes
});

function isHoliday(day: Date): boolean | HolidaysTypes.Holiday[] {
  return holidays.isHoliday(day);
}

export function isBusinessDay(day: Date): boolean {
  if (isHoliday(day)) return false;
  if (workingWeekDays.includes(day.getDay())) return true;

  return false;
}

export function nextBusinessDay(day: dayjs.Dayjs): dayjs.Dayjs {
  while (!isBusinessDay(day.toDate())) day = day.add(1, "d");

  return day.startOf("d");
}
