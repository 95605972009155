import State from "@/types/auth-state";

export default {
  authenticatedUser: (state: State) => state.user,
  userPermissions: (state: State) => state.userPermissions,
  isAuthenticated: (state: State) => state.isAuthenticated,
  authenticationStatus: (state: State) => {
    return state.authenticationStatus
      ? state.authenticationStatus
      : { variant: "secondary" };
  },
  hasAuthenticationStatus: (state: State) => {
    return !!state.authenticationStatus;
  },
  token: (state: State) => state.token,
  refreshToken: (state: State) => state.refreshToken,
  currentUserCompanyId: (state: State) => state.currentUserCompanyId,
  loggedPartnerId: (state: State) => state.partnerId,
  loggedPartnerIdentifier: (state: State) => state.partnerIdentifier
};
