
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import DebtAgreementsTable from "@/components/debt-agreements/DebtAgreementsTable.vue";
import DebtAgreementService, {
  ListDebtAgreementsParamsDto,
  ListDebtAgreementsResponseDto
} from "@/services/debt-agreement-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import { DebtAgreement } from "../types/debt-agreement";
import DebtAgreementDetailModal from "@/components/debt-agreements/DebtAgreementDetailModal.vue";
import DebtAgreementEditModal from "@/components/debt-agreements/DebtAgreementEditModal.vue";
import DebtAgreementCancelModal from "@/components/debt-agreements/DebtAgreementCancelModal.vue";

@Component({
  components: {
    Page,
    DebtAgreementsTable,
    DebtAgreementDetailModal,
    DebtAgreementEditModal,
    DebtAgreementCancelModal
  }
})
export default class DebtAgreements extends Vue {
  service: DebtAgreementService = DebtAgreementService.getInstance();
  debtAgreements: ListDebtAgreementsResponseDto = { data: [], total: 0 };
  selectedDebtAgreement: DebtAgreement | null = null;
  showDebtAgreementDetailModal: boolean = false;
  showDebtAgreementEditModal: boolean = false;
  showDebtAgreementCancelModal: boolean = false;
  refreshDebtAgreements: number = 0;

  async fetchDebtAgreements(
    options: ListDebtAgreementsParamsDto
  ): Promise<void> {
    let [err, data] = await this.service.listDebtAgreements(options);

    if (!err) {
      this.debtAgreements = data!;
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Acordo", type: "error", text: message });
    }
  }

  handleShowDebtAgreement(debtAgreement: DebtAgreement): void {
    this.selectedDebtAgreement = debtAgreement;
    this.showDebtAgreementDetailModal = true;
  }

  handleNewDebtAgreement(): void {
    this.selectedDebtAgreement = null;
    this.showDebtAgreementEditModal = true;
  }

  handleEditDebtAgreement(debtAgreement: DebtAgreement): void {
    this.selectedDebtAgreement = debtAgreement;
    this.showDebtAgreementEditModal = true;
  }

  handleCancelDebtAgreement(debtAgreement: DebtAgreement): void {
    this.selectedDebtAgreement = debtAgreement;
    this.showDebtAgreementCancelModal = true;
  }

  handleRefreshDebtAgreements(): void {
    this.refreshDebtAgreements++;
  }

  closeModals(): void {
    this.showDebtAgreementDetailModal = false;
    this.showDebtAgreementEditModal = false;
    this.showDebtAgreementCancelModal = false;
  }
}
