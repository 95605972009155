
import { Vue, Component, Prop } from "vue-property-decorator";
import Breadcrumb from "@/components/core/dashboard/Breadcrumb.vue";
import store from "@/store";

@Component({
  components: { Breadcrumb },
})
export default class Page extends Vue {
  constructor() {
    super();
  }

  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "" }) readonly subtitle1!: string;
  @Prop({ default: "" }) readonly subtitle2!: string;

  get shouldDisplayPage() {
    const pageRequiresCompanyId = this.$route.meta!.companyIdRequired;
    const hasInformedCompanyId = this.userHasSelectedACompany;

    return (
      !pageRequiresCompanyId || (pageRequiresCompanyId && hasInformedCompanyId)
    );
  }

  get userHasSelectedACompany() {
    return !!store.getters["auth/currentUserCompanyId"];
  }
}
