import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

export function currentOrFutureDate(date: string, extraDays = 0): boolean {
  return dayjs(date, "DD/MM/YYYY", true).isSameOrAfter(
    dayjs().add(extraDays, "days"),
    "date"
  );
}
