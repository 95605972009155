
import Modal from "@/components/Modal.vue";
import dayjs from "@/plugins/day-js";
import {
  default as MarginBase,
  default as MarginBaseRegister
} from "@/types/margin-base";
import debounce from "debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { ListMarginBaseRegistersParams } from "../../services/margin-base-service";

interface CustomDataOptions extends DataOptions {
  marginBaseId: number;
}

interface MarginBaseRegistersActions {
  registers?: boolean;
  manage?: boolean;
  delete?: boolean;
}

@Component({
  components: { Modal }
})
export default class MarginBaseRegistersTable extends Vue {
  @Prop({ default: [] }) readonly marginBaseRegisters!: {
    items: Array<MarginBaseRegister>;
    total: number;
  };
  @Prop() fetch!: (options: ListMarginBaseRegistersParams) => Promise<void>;
  @Prop() show!: boolean;
  @Prop() refresh!: number;
  @Prop() item!: MarginBase;

  options: CustomDataOptions;
  headers: Array<DataTableHeader>;
  search: string = "";
  loading: boolean;

  constructor() {
    super();
    this.headers = [
      { text: "CPF", value: "cpf", sortable: false },
      { text: "Ocupação", value: "occupation", sortable: false },
      { text: "Renda líquida", value: "liquidIncome", sortable: true },
      { text: "Data de admissão", value: "admissionDate", sortable: true }
    ];
    this.loading = false;
    this.options = {} as CustomDataOptions;
  }

  mounted() {
    this.$watch("options", (val: CustomDataOptions) => {
      const options = { ...val, marginBaseId: this.item.id! };
      this.fetchMarginBaseRegisters(options);
    });
  }

  @Watch("refresh")
  onRefreshChange() {
    this.options = { ...this.options };
  }

  @Watch("search")
  onSearchChanged(val: string) {
    const { itemsPerPage, sortBy, sortDesc } = this.options;
    this.searchFunction(
      1,
      itemsPerPage,
      val,
      this.handleSort(sortBy, sortDesc)!
    );
  }

  get searchFunction() {
    return debounce(
      async (
        page: number,
        itemsPerPage: number,
        search: string,
        sort: string
      ) => {
        this.startLoading();
        await this.fetch({
          marginBaseId: this.item.id!,
          page: page,
          limit: itemsPerPage,
          search: search,
          sort: sort
        });
        this.stopLoading();
      },
      700
    );
  }

  get formTitle() {
    return `${this.item.fileName}:
      ${dayjs(this.item.admissionDate).format("DD/MM/YYYY")}`;
  }

  async fetchMarginBaseRegisters(val: CustomDataOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = val;

    this.startLoading();
    await this.fetch({
      marginBaseId: this.item.id!,
      page: page,
      limit: itemsPerPage,
      search: this.search,
      sort: this.handleSort(sortBy, sortDesc)
    });
    this.stopLoading();
  }

  emitAction(
    item: MarginBaseRegister,
    operation: MarginBaseRegistersActions
  ): void {
    if (this.marginBaseRegisters.items.indexOf(item) > -1)
      item.index = this.marginBaseRegisters.items.indexOf(item);
    this.$emit("action", { item, ...operation });
  }

  handleSort(sortBy: Array<string>, sortDesc: Array<boolean>) {
    const attr = sortBy && sortBy[0] ? sortBy[0] : "created_at";
    const order = sortDesc && sortDesc[0] ? "ASC" : "DESC";

    return attr ? `${attr}:${order}` : undefined;
  }

  handleCloseModal() {
    this.$emit("close", true);
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }

  formatDate(date: string) {
    return dayjs(date).format("DD/MM/YYYY");
  }
}
