import axios from 'axios';
import { Vue } from 'vue-property-decorator'
import { EventBus } from '@/events/event-bus';


/* Need to be refactored to typescript,
   with module augmentation (bing on Vue instance)
   https://www.typescriptlang.org/docs/handbook/declaration-merging.html#module-augmentation
*/
Vue.prototype.$http = axios;
// axios.defaults.baseURL = 'https://gooroo.dev/api/';
axios.defaults.headers.get.Accept = 'application/json';

let requests = 0;

// Start loading before a request is made
axios.interceptors.request.use((config) => {
    EventBus.$emit('requests', ++requests);
    return config;
}, (error) => {
    EventBus.$emit('requests', --requests);
    return Promise.reject(error);
});

// Stop loading after a request is done
axios.interceptors.response.use((response) => {
    EventBus.$emit('requests', --requests);
    return response;
}, (error) => {
    EventBus.$emit('requests', --requests);
    return Promise.reject(error);
});


export default axios;