
import FileUpload from "@/components/FileUpload.vue";
import Modal from "@/components/Modal.vue";
import MarginBase from "@/types/margin-base";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: { ValidationObserver, Modal, FileUpload },
})
export default class MarginBaseManageModal extends Vue {
  @Prop() show!: boolean;
  @Prop() readonly item!: MarginBase;
  file: any;
  isUploading: boolean;

  constructor() {
    super();
    this.file = null;
    this.isUploading = false;
  }

  $refs!: {
    mb: InstanceType<typeof FileUpload>;
  };

  get formTitle() {
    return this.item.index === null
      ? "Importar base de margem"
      : "Visualizar base de margem";
  }

  get acceptedTypes() {
    return "application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet";
  }

  get acceptedTypesAlias() {
    return {
      "application/vnd.ms-excel": ".xls",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet":
        ".xlsx",
    };
  }

  get uploadAreaMessage() {
    return "Arraste e solte o arquivo de base margem ou <span class='filepond--label-action'> Clique aqui </span>";
  }

  handleUploading($event: boolean): void {
    this.isUploading = $event;
  }

  handleUploaded($event: boolean): void {
    this.$emit("isUploaded", $event);
  }

  handleFilesChanged(file: any): void {
    this.file = file;
  }

  close(): void {
    if (!this.isUploading) {
      this.$refs.mb.handleRemoveLocalFiles();
      this.$emit("close");
    } else {
      this.$notify({
        title: "Base de margem",
        type: "warning",
        text: "Aguarde a finalização do processo.",
      });
    }
  }

  get uploadUrl() {
    return `${process.env.VUE_APP_MARGIN_BASE_URL!}/margin-base/create`;
  }
}
