import Vue from "vue";
import VueRouter from "vue-router";
import routes from "./routes";
import store from "@/store";

import { hasPermissions } from "@/utils/authorization";
Vue.use(VueRouter);

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

const authRoutes = ["SignIn"];

router.beforeEach((to, from, next) => {
  if (to.name === "SignIn" && store.getters["auth/isAuthenticated"]) {
    router.push({
      name: "Home"
    });
    return;
  }

  if (to.meta && to.meta.authRequired !== undefined) {
    if (to.meta.authRequired) {
      if (store.getters["auth/isAuthenticated"]) {
        const userPermissions = store.getters["auth/userPermissions"];
        const userType = store.getters["auth/authenticatedUser"].type;
        const permissionRequirementsForUserType =
          to.meta.requiredPermissions.find(
            (item: { userType: string; permissions: string[] }) =>
              item.userType === userType
          );
        const requiredPermissions =
          permissionRequirementsForUserType?.permissions;

        /*
         * If user type's permission requirements are not explicitly defined
         * OR user does not have the required permissions,
         * then they CANNOT access the module
         */
        if (
          !permissionRequirementsForUserType ||
          !hasPermissions(requiredPermissions, userPermissions)
        ) {
          Vue.notify({
            type: "error",
            text: "Você não tem permissão para acessar essa página"
          });
          router.push({
            name: "Home"
          });
          return;
        }
      } else {
        router.push({ name: "Auth" });
        return;
      }
    }

    if (
      store.getters["auth/isAuthenticated"] &&
      authRoutes.includes(to.name!)
    ) {
      router.push({ name: "Home" });
      return;
    }
  }

  if (to.meta && to.meta.title) {
    document.title = to.meta.title;
  }

  next();
  return;
});

// router.beforeEach((to, from, next) => {
//   if (to.name === 'Login' && localStorage.getItem('token')) {
//     router.push({
//       name: 'Home',
//     });
//   }

//   else if (to.matched.some((record) => record.meta.authRequired)) {

//     if (!localStorage.getItem('token')) {
//       return next({ name: 'Login' });
//     }
//     else {
//       if (!hasPermissions(to.meta?.requiredPermissions)) {
//         router.push({
//           name: 'PageNotAuthorized'
//         });
//       }
//     }
//   }
//   return next();
// });

export default router;
