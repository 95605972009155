import dayjs from "dayjs";

export const isAfterMinimumDate = ({
  value,
  minDate
}: {
  value: string;
  minDate: string;
}): boolean => {
  try {
    const parsedMinDate = dayjs(minDate, "DD/MM/YYYY", true);
    const parsedValueDate = dayjs(value, "DD/MM/YYYY", true);

    if (parsedValueDate.isAfter(parsedMinDate)) return true;

    return false;
  } catch (e) {
    return false;
  }
};
