/**
 * Checks if the input string follows a valid pattern for a branch.
 * Valid patterns:
 * - Exactly 3 digits.
 * - Exactly 4 digits.
 * - Exactly 4 digits, followed by a dash and one additional digit.
 *
 */
export default function isValidBranch(value: string): boolean {
  const branchPattern = /^(\d{3}|\d{4}|\d{4}-\d{1})$/;

  return branchPattern.test(value);
}
