import HttpClient, { Response } from "../http-client";
import Batch from "@/types/batch";
import Payments from "./types/payments";
import PaymentOrigin from "../../types/payment-origin";
import PaymentType from "../../types/payment-type";
import listPaymentsParams from "./types/list-payments-params";
import ListPaymentsResponse from "./types/list-payments-response";
import PaymentStatus from "../../types/payment-status";
import UpdatePaymentData from "./types/update-payment";
import Payment from "@/types/payment";
import PayerType from "@/types/payer-type";
import { ManuallyGenerateBankSlipForCompanyDTO } from "./types/manually-generate-bank-slip-for-company";
import { ManuallyGenerateBankSlipForBorrowerDTO } from "./types/manually-generate-bank-slip-for-borrower";
import { ManuallyGenerateIndividualBanksSlipsForBorrowerDTO } from "./types/manually-generate-individual-banks-slips-for-borrower";
import { ManuallyGenerateBankSlipDTO } from "./types/manually-generate-bank-slip";
import FindDebtAgreementPaymentData from "./types/find-debt-agreement-payment";
import PaymentDebtAgreementInstallment from "@/types/payment-debt-agreement-installment";
import LoanInstallment from "@/types/loan-installment";
import { DebtAgreementInstallment } from "@/types/debt-agreement-installment";

export default class PaymentService extends HttpClient {
  private static classInstance?: PaymentService;

  private constructor() {
    super(process.env.VUE_APP_PAYMENT_URL!);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new PaymentService();
    }

    return this.classInstance;
  }

  public async listPaymentsByBatchIds(
    batchIds: number[]
  ): Promise<Response<Payments>> {
    return this.axiosCall<Payments>({
      method: "GET",
      url: `/payments/list`,
      params: { batchIds: batchIds.join(",") }
    });
  }

  public async listAllPayments(
    options: listPaymentsParams
  ): Promise<Response<ListPaymentsResponse>> {
    return this.axiosCall<ListPaymentsResponse>({
      method: "GET",
      url: "/payments/list-all",
      params: options
    });
  }

  public async manuallyGenerateBankSlipForCompany(
    data: ManuallyGenerateBankSlipForCompanyDTO
  ): Promise<Response<Batch>> {
    return this.axiosCall<Batch>({
      method: "POST",
      url: `/payments/bank-slip/manual-for-company`,
      data: data
    });
  }

  public async manuallyGenerateBankSlipForBorrower(
    data:
      | ManuallyGenerateBankSlipForBorrowerDTO
      | ManuallyGenerateIndividualBanksSlipsForBorrowerDTO
  ): Promise<Response<Batch>> {
    return this.axiosCall<Batch>({
      method: "POST",
      url: `/payments/bank-slip/manual-for-borrower`,
      data: data
    });
  }
  public async manuallyGenerateBankSlip(
    data: ManuallyGenerateBankSlipDTO
  ): Promise<Response<Batch>> {
    return this.axiosCall<Batch>({
      method: "POST",
      url: `/payments/bank-slip`,
      data: data
    });
  }

  public async manuallyGenerateBankSlipForSettleBorrowerContract(
    data: ManuallyGenerateBankSlipForBorrowerDTO
  ): Promise<Response<Batch>> {
    return this.axiosCall<Batch>({
      method: "POST",
      url: `/payments/bank-slip/manual-for-settle-borrower-contract`,
      data: data
    });
  }

  public async listPaymentOrigins(): Promise<Response<PaymentOrigin[]>> {
    return this.axiosCall<PaymentOrigin[]>({
      method: "GET",
      url: `/payments/origins`
    });
  }

  public async listPaymentPayerTypes(): Promise<Response<PayerType[]>> {
    return this.axiosCall<PayerType[]>({
      method: "GET",
      url: `/payments/payer-types`
    });
  }

  public async listPaymentTypes(): Promise<Response<PaymentType[]>> {
    return this.axiosCall<PaymentType[]>({
      method: "GET",
      url: `/payments/types`
    });
  }

  public async listPaymentStatus(): Promise<Response<PaymentStatus[]>> {
    return this.axiosCall<PaymentStatus[]>({
      method: "GET",
      url: `/payments/status`
    });
  }

  public async listInstallmentsByPaymentId(
    paymentId: number
  ): Promise<Response<LoanInstallment[] | DebtAgreementInstallment[]>> {
    return this.axiosCall<LoanInstallment[] | DebtAgreementInstallment[]>({
      method: "GET",
      url: `/payments//list-installments-by-payment-id/${paymentId}`
    });
  }

  public async updatePayment(data: UpdatePaymentData): Promise<Response<any>> {
    return this.axiosCall<Payment>({
      method: "POST",
      url: `/payments/update`,
      data: data
    });
  }

  public async findDebtAgreementPayment(
    params: FindDebtAgreementPaymentData
  ): Promise<Response<PaymentDebtAgreementInstallment>> {
    return this.axiosCall<PaymentDebtAgreementInstallment>({
      method: "GET",
      url: "/payments/payment-debt-installment/find-payment",
      params
    });
  }

  public async findBankSlipByPaymentId(
    paymentId: number
  ): Promise<Response<any>> {
    return this.axiosCall<any>({
      method: "GET",
      url: `/payments/find-bank-slip/${paymentId}`
    });
  }
}
