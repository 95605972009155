
import { Vue, Component } from "vue-property-decorator";
import Page from "@/components/core/dashboard/Page.vue";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import DebtAgreementsInstallmentsTable from "@/components/debt-agreements/DebtAgreementsInstallmentsTable.vue";
import { DebtAgreementInstallment } from "@/types/debt-agreement-installment";
import DebtAgreementInstallmentsService, {
  InstallmentsDebtAgreementFilters,
  ListInstallmentsDebtAgreementsResponseDto
} from "@/services/installments-debt-agreement-service";
import PaymentService from "@/services/payment-service";
import { DebtAgreementInstallmentStatusEnum } from "@/types/debt-agreement-installment-status";
import { differenceInDays } from "date-fns/differenceInDays";

@Component({
  components: {
    Page,
    DebtAgreementsInstallmentsTable
  }
})
export default class DebtAgreementsInstallments extends Vue {
  service: DebtAgreementInstallmentsService =
    DebtAgreementInstallmentsService.getInstance();
  paymentService: PaymentService = PaymentService.getInstance();

  debtAgreementsInstallments: ListInstallmentsDebtAgreementsResponseDto = {
    data: [],
    total: 0
  };
  selectedDebtAgreementInstallment: DebtAgreementInstallment | null = null;
  refreshDebtAgreementsInstallments: number = 0;

  async fetchDebtAgreementsInstallments(
    options: InstallmentsDebtAgreementFilters
  ): Promise<void> {
    let [err, data] = await this.service.listDebtAgreementsInstallments({
      loadRelations: true,
      ...options
    });

    if (!err) {
      let installments: DebtAgreementInstallment[] = [];
      for (const installment of data.data) {
        if (
          installment.statusId === DebtAgreementInstallmentStatusEnum.PAID.id
        ) {
          const [error, data] =
            await this.paymentService.findDebtAgreementPayment({
              debtAgreementInstallmentId: installment.id,
              debtAgreementId: installment.debtAgreementId
            });
          if (!error && data && data.payment) {
            const dueDate = new Date(installment.dueDate);
            const paidDate = data.payment.paidDate
              ? new Date(data.payment.paidDate)
              : new Date(installment.dueDate);
            const diffInDays = differenceInDays(dueDate, paidDate);
            installment.lateDays = diffInDays;
            installments.push(installment);
          }
        } else {
          const today = new Date();
          const date = new Date(installment.dueDate);
          const diffInDays = differenceInDays(today, date);

          installment.lateDays = diffInDays <= 0 ? 0 : diffInDays;
          installments.push(installment);
        }
      }

      this.debtAgreementsInstallments = {
        total: data.total,
        data: installments
      };
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Parcelas Acordos", type: "error", text: message });
    }
  }

  handleShowDebtAgreementInstallment(
    debtAgreementInstallment: DebtAgreementInstallment
  ): void {
    this.selectedDebtAgreementInstallment = debtAgreementInstallment;
  }
}
