export default {
  user: null,
  partnerId: null,
  partnerIdentifier: "",
  userPermissions: [],
  currentUserCompanyId: null,
  isAuthenticated: false,
  authenticationStatus: null,
  token: "",
  refreshToken: ""
};
