import HttpClient, { Response } from "../http-client";
import Profile from "@/types/profile";
import User from "@/types/user";
import SignUpAdminGoorooData from "./types/signup-admin-gooroo-data";
import SignUpAdminGroupData from "./types/signup-admin-group-data";
import SignUpEmployeeData from "./types/signup-employee-data";
import SignUpPartnerData from "./types/signup-partner-data";
import UpdateUserData from "./types/update-user-data";
import PermissionAccess from "@/types/permission";
import ApiClients from "@/types/api-client";

export interface UserTypeOption {
  value: string;
  text: string;
}
export default class SafetyService extends HttpClient {
  private static classInstance?: SafetyService;

  private constructor() {
    super(process.env.VUE_APP_SAFETY_URL!);
  }

  public userTypes: UserTypeOption[] = [
    { value: "ADMIN_GOOROO", text: "Administrador Gooroo" },
    { value: "ADMIN_GROUP", text: "Administrador do grupo" },
    { value: "EMPLOYEE", text: "Funcionário da empresa" },
    { value: "BORROWER", text: "Colaborador" },
    { value: "PARTNER_MASTER", text: "Parceiro master" },
    { value: "PARTNER", text: "Parceiro" }
  ];

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new SafetyService();
    }

    return this.classInstance;
  }

  public async listPermissions(
    userType: string
  ): Promise<Response<ListPermissionsResponse>> {
    return this.axiosCall<ListPermissionsResponse>({
      method: "GET",
      url: `/permissions/list/${userType}`
    });
  }

  public async listPermissionsByUserId(
    userId: number
  ): Promise<Response<PermissionAccess[]>> {
    return this.axiosCall<PermissionAccess[]>({
      method: "GET",
      url: `/permissions/list-by-user/${userId}`
    });
  }

  public async createProfile(data: {
    companyId?: number;
    name: string;
    permissions: string[];
  }): Promise<any> {
    return this.axiosCall<Profile>({
      method: "POST",
      url: "/profiles/create",
      data: data
    });
  }

  public async signupAdminGooroo(data: SignUpAdminGoorooData): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: "/users/signup/admin-gooroo",
      data: data
    });
  }

  public async signupAdminGroup(data: SignUpAdminGroupData): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: "/users/signup/admin-group",
      data: data
    });
  }

  public async signupPartner(data: SignUpPartnerData): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: "/users/signup/partner",
      data: data
    });
  }

  public async signupEmployee(data: SignUpEmployeeData): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: "/users/signup/employee",
      data: data
    });
  }

  public async updateUser(id: number, data: UpdateUserData): Promise<any> {
    return this.axiosCall<User>({
      method: "PATCH",
      url: "/users/" + id + "/update",
      data: data
    });
  }

  public async updateProfile(
    id: number,
    data: {
      name: string;
      permissions: string[];
    }
  ): Promise<any> {
    return this.axiosCall<Profile>({
      method: "PUT",
      url: `/profiles/update/${id}`,
      data: data
    });
  }

  public async listProfiles(): Promise<any> {
    return this.axiosCall<Profile[]>({ method: "GET", url: `/profiles/list` });
  }

  public async approveUser(id: number): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: `/users/${id}/approve`
    });
  }

  public async reproveUser(id: number): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: `/users/${id}/reprove`
    });
  }

  public async activateUser(id: number): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: `/users/${id}/activate`
    });
  }

  public async inactivateUser(id: number): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: `/users/${id}/inactivate`
    });
  }

  public async deleteUser(id: number): Promise<any> {
    return this.axiosCall<User>({
      method: "DELETE",
      url: `/users/${id}/delete-manually`
    });
  }

  public async forgotPassword(user: User): Promise<any> {
    return this.axiosCall<User>({
      method: "POST",
      url: `/users/forgot-password`,
      data: {
        email: user.email,
        phone: user.phone
      }
    });
  }

  public async listUsers(options: UserFilters): Promise<Response<UserList>> {
    const [error, result] = await this.axiosCall<UserList>({
      method: "GET",
      url: "/users/list",
      params: {
        ...options,
        types: options.types ? options.types.join(",") : undefined,
        userIds:
          options.userIds && options.userIds.length > 0
            ? options.userIds.join(",")
            : undefined
      }
    });
    return [error, result];
  }

  public async listApiClients(
    options: ListApiClientsFilters
  ): Promise<Response<ApiClients[]>> {
    const [error, result] = await this.axiosCall<ApiClients[]>({
      method: "GET",
      url: "/users/list/api-clients",
      params: {
        ...options
      }
    });
    return [error, result];
  }
}

export interface UserFilters {
  page: number;
  limit: number;
  search?: string;
  sort?: string;
  userIds?: number[];
  types?: string[];
  status?: number;
  companyId?: number;
  partnerId?: number;
  cpf?: string;
  email?: string;
  phone?: string;
}

export interface UserList {
  items: Array<User>;
  total: number;
}

export type ListPermissionsResponse = ListPermissionsItem[];

export interface ListPermissionsItem {
  module: string;
  permissions: PermissionAccess[];
}

export interface ListApiClientsFilters {
  apiClientIds?: number[];
  page: number;
  limit: number;
  sort?: string;
  search?: string;
  status?: number;
}
