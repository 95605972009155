
import Modal from "@/components/Modal.vue";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { mask } from "vue-the-mask";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DebtAgreement } from "../../types/debt-agreement";
import DebtAgreementEditModalCpfInput from "./DebtAgreementEditModalCpfInput.vue";
import DebtAgreementEditModalConditions from "./DebtAgreementEditModalConditions.vue";
import DebtAgreementEditModalProposal from "./DebtAgreementEditModalProposal.vue";

enum Step {
  CPF = 1,
  CONDITIONS = 2,
  PROPOSAL = 3
}

@Component({
  components: {
    ValidationObserver,
    ValidationProvider,
    Modal,
    DebtAgreementEditModalCpfInput,
    DebtAgreementEditModalConditions,
    DebtAgreementEditModalProposal
  },
  directives: { mask }
})
export default class DebtAgreementEditModal extends Vue {
  @Prop() debtAgreementToContinue!: DebtAgreement | undefined;
  debtAgreement: DebtAgreement | undefined = {
    ...this.debtAgreementToContinue
  };

  Step = Step;
  step: number = Step.CPF;
  borrowerCpf: string = "";

  mounted(): void {
    if (this.debtAgreementToContinue?.id) {
      this.step = Step.CONDITIONS;
    }
  }

  onInputCpf(cpf: string): void {
    this.borrowerCpf = cpf;
  }

  close(): void {
    this.$emit("close");
    this.refresh();
  }

  refresh(): void {
    this.$emit("refresh");
  }
}
