import Vue from "vue";
import VTextFieldWithValidation from "@/components/core/forms/VTextFieldWithValidation.vue";
import VTextAreaWithValidation from "@/components/core/forms/VTextAreaWithValidation.vue";
import VSelectWithValidation from "@/components/core/forms/VSelectWithValidation.vue";
import VAutocompleteWithValidation from "@/components/core/forms/VAutocompleteWithValidation.vue";
import VInfiniteScroll from "@/components/core/forms/VInfiniteScroll.vue";

Vue.component("VTextFieldWithValidation", VTextFieldWithValidation);
Vue.component("VTextAreaWithValidation", VTextAreaWithValidation);
Vue.component("VSelectWithValidation", VSelectWithValidation);
Vue.component("VAutocompleteWithValidation", VAutocompleteWithValidation);
Vue.component("VInfiniteScroll", VInfiniteScroll);
