var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-autocomplete',{attrs:{"label":"Tomador","return-object":"","items":_vm.borrowersData.items,"item-value":"id","item-text":"borrowerNameOrCpf","search-input":_vm.search,"no-filter":"","loading":_vm.loadingBorrowers,"loading-text":"Carregando tomadores...","no-data-text":"Não foram encontrados tomadores","clearable":"","outlined":""},on:{"input":_vm.emitValue,"update:search-input":(value) => {
      _vm.search = null;
      _vm._asyncSetSearch(value);

      if (!_vm.selectedBorrower || value !== _vm.selectedBorrower.borrowerNameOrCpf) {
        _vm.fetchMoreBorrowers({ reset: true });
      }
    }},scopedSlots:_vm._u([{key:"append-item",fn:function(){return [_c('div',{directives:[{name:"intersect",rawName:"v-intersect",value:(_vm.endIntersect),expression:"endIntersect"}]})]},proxy:true}]),model:{value:(_vm.selectedBorrower),callback:function ($$v) {_vm.selectedBorrower=$$v},expression:"selectedBorrower"}})
}
var staticRenderFns = []

export { render, staticRenderFns }