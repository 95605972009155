import ptBR from './pt-BR';
import en from './en';
import es from './es';

const messages = {
    ptBR: ptBR,
    en: en,
    es: es
};

export default messages;