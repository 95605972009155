/* eslint-disable */

import axios from "axios";
import axiosRetry from "axios-retry";
axiosRetry(axios, { retries: 3 });

axios.defaults.headers.common["iorigin"] = "AP";

/**
 * TODO: Change any types and enable eslint for file
 */

export default {
  clearAuthenticationStatus: (context: any) => {
    context.commit("clearAuthenticationStatus", null);
  },

  signUpAdminGroup: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const response = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/signup/admin-group",
        {
          name: params.name,
          email: params.email,
          phone: params.phone,
          password: params.password
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      return response.data;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  signUpPartnerAdmin: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const response = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/signup/partner-master",
        {
          name: params.name,
          email: params.email,
          phone: params.phone,
          password: params.password,
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      return response.data;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  signIn: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const response = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/signin/administrator-portal",
        {
          email: params.email,
          password: params.password
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      context.commit("setUserAuthenticated", response.data.user);
      context.commit("setUserToken", response.data.auth.token);
      context.commit("setUserRefreshToken", response.data.auth.refreshToken);
      context.commit("setUserPermissions", response.data.permissions);

      return response.data.user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  signOut: async (context: any) => {
    try {
      context.commit("auth/clearAuthentication", null, { root: true });
    } catch (err) {
      throw err;
    }
  },

  refreshToken: async (context: any, params: any) => {
    try {
      const response = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/refresh-token",
        {
          email: params.email,
          refreshToken: params.refreshToken
        },
        {
          headers: {
            refreshTokenRequest: "true"
          }
        }
      );
      context.commit("setUserToken", response.data.auth.token);

      return response.data.user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  forgotPassword: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const { data: user } = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/forgot-password",
        {
          email: params.email
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      // Auth.signIn(params.phone, params.password);

      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  forgotPasswordSubmit: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const { data: user } = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/confirm-forgot-password",
        {
          email: params.email,
          password: params.password,
          verificationCode: params.verificationCode
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  confirmSignUp: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const { data: user } = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/confirm-signup",
        {
          email: params.email,
          verificationCode: params.code
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );

      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  resendSignUp: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const { data: user } = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/resend-signup",
        {
          email: params.email
        },
        {
          headers: { gcaptcha: params.captchaToken }
        }
      );
      // Auth.signIn(params.phone, params.password);
      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  },

  completeNewPassword: async (context: any, params: any) => {
    context.commit("auth/clearAuthenticationStatus", null, { root: true });
    try {
      const { data: user } = await axios.post(
        process.env.VUE_APP_SAFETY_URL! + "/users/complete-new-password",
        {
          email: params.user.email,
          newPassword: params.newPassword,
          password: params.password,
          verificationcode: params.verificationcode
        }
      );
      // Auth.signIn(params.phone, params.password);
      return user;
    } catch (err) {
      context.commit("auth/setAuthenticationError", err, { root: true });
      throw err;
    }
  }
};
