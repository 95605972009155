import MarginBase from "@/types/margin-base";
import MarginBaseRegister from "@/types/margin-base-register";
import HttpClient, { Response } from "./http-client";
import Company from "../types/company";
import Products from "@/types/products";

export default class MarginBaseService extends HttpClient {
  private static classInstance?: MarginBaseService;

  private constructor() {
    super(process.env.VUE_APP_MARGIN_BASE_URL!);
  }

  public static getInstance(): MarginBaseService {
    if (!this.classInstance) {
      this.classInstance = new MarginBaseService();
    }
    return this.classInstance;
  }

  public async listMarginBases(options: ListMarginBaseParams): Promise<any> {
    return this.axiosCall<MarginBaseList>({
      method: "GET",
      url: "/margin-base/list",
      params: options
    });
  }

  public async deleteMarginBase(params: DeleteMarginBaseParams): Promise<any> {
    return this.axiosCall<boolean>({
      method: "DELETE",
      url: `/margin-base/delete/${params.marginBaseId}`
    });
  }

  public async generateMarginBaseRegisterReportXls(
    options: MarginBaseRegisterFilterList
  ): Promise<Response<GenerateMarginBaseReportXlsData>> {
    return this.axiosCall<GenerateMarginBaseReportXlsData>({
      method: "POST",
      url: `/margin-base-register/report/margin-bases-register-reports/generate-xls-token`,
      params: options
    });
  }

  // public async createMarginBase(data: FormData): Promise<any> {
  //   return this.axiosCall<MarginBase>({
  //     method: "POST",
  //     url: `/margin-base/create`,
  //     data: data,
  //     // headers: {
  //     //   "Content-Type": "multipart/form-data",
  //     // },
  //   });
  // }

  public async listMarginBaseRegisters(
    options: ListMarginBaseRegistersParams
  ): Promise<any> {
    interface MarginBaseRegistersList {
      items: MarginBaseRegister[];
      total: number;
    }
    return this.axiosCall<MarginBaseRegistersList>({
      method: "GET",
      url: "/margin-base-register/list",
      params: options
    });
  }

  public async listMarginBaseRegisterCountForCompanies(
    options: ListMarginCountForCompaniesParams
  ): Promise<Response<MarginBaseRegisterCountList>> {
    return await this.axiosCall<MarginBaseRegisterCountList>({
      method: "GET",
      url: "/margin-base-register/list-count-by-company",
      params: {
        ...options,
        companiesIds: options.companiesIds.join(",")
      }
    });
  }

  public async listAllMarginBaseRegister(
    options: MarginBaseRegisterFilterList
  ): Promise<Response<MarginBaseRegisterList>> {
    return await this.axiosCall<MarginBaseRegisterList>({
      method: "GET",
      url: "/margin-base-register/report/list-all-margin-registers",
      params: {
        ...options
      }
    });
  }

  public async createManualMarginBaseRegister(
    params: MarginBaseRegisterCreateDTO
  ): Promise<Response<boolean>> {
    return await this.axiosCall<boolean>({
      method: "POST",
      url: "/margin-base/create-manual-margin-registers",
      data: params
    });
  }

  public async listMarginBaseRegistersGroupedByCompany(
    cpf: string
  ): Promise<Response<MarginBaseRegistersGroupedByCompany[]>> {
    return this.axiosCall<MarginBaseRegistersGroupedByCompany[]>({
      method: "GET",
      url: `/margin-base-register/find-grouped-by-company/${cpf}`
    });
  }

  public async findConsignetPartnerships(): Promise<
    Response<ConsignetPartnership[]>
  > {
    return this.axiosCall<ConsignetPartnership[]>({
      method: "GET",
      url: `/margin-base/find-consignet-partnerships`
    });
  }

  public async findCompanyRelatedConsignetPartnerships(
    cpf: string
  ): Promise<Response<Company[]>> {
    return this.axiosCall<Company[]>({
      method: "GET",
      url: `/margin-base/find-company-related-consignet-partnerships/${cpf}`
    });
  }

  public async findValidConsignetEmployeeRegistrations(
    params: FindConsignetEmployeeRegistrationsParams
  ): Promise<Response<ConsignetRegistration[]>> {
    return this.axiosCall<ConsignetRegistration[]>({
      method: "GET",
      url: `/margin-base/find-valid-consignet-employee-registrations`,
      params
    });
  }

  public async findCurrentConsignetEmployeeRegistrationMarginBase(
    params: FindConsignetCurrentEmployeeRegistrationMarginBaseParams
  ): Promise<Response<ConsignetMarginBase>> {
    return this.axiosCall<ConsignetMarginBase>({
      method: "GET",
      url: `/margin-base/find-current-consignet-employee-registration-margin-base`,
      params
    });
  }

  public async listActiveConsignetContracts(
    params: ListActiveConsignetContractsParams
  ): Promise<Response<ConsignetActiveContracts>> {
    return this.axiosCall<ConsignetActiveContracts>({
      method: "GET",
      url: `/margin-base/list-active-consignet-contracts`,
      params
    });
  }

  public async findById(id: number): Promise<Response<MarginBaseRegister>> {
    return this.axiosCall<MarginBaseRegister>({
      method: "GET",
      url: `/margin-base-register/find-by-id/${id}`
    });
  }

  public async createConsignetMarginBase(
    data: CreateConsignetMarginBase
  ): Promise<Response<MarginBase>> {
    return this.axiosCall<MarginBase>({
      method: "POST",
      url: `/margin-base/create-consignet-margin-base`,
      data
    });
  }
}

export interface FindConsignetEmployeeRegistrationsParams {
  cpf: string;
  consignetPartnershipId: number;
}
export interface FindConsignetCurrentEmployeeRegistrationMarginBaseParams {
  consignetRegistrationId: number;
  consignetPartnershipId: number;
  cpf: string;
}

export interface ListActiveConsignetContractsParams {
  consignetRegistrationId: number;
  consignetPartnershipId: number;
  cpf: string;
}

export interface ConsignetRegistration {
  registerId: number;
  registration: string;
  admissionDate: string;
  occupation: string;
  cnpj: string;
}

export type ConsignetActiveContracts = Array<Contract>;

interface Contract {
  adf: number;
  registration: string;
  numberOfInstallments: number;
  installmentsValue: number;
  estimatedDebt: number;
}

interface ConsignetMarginBase {
  effectiveDate: string;
  availableMarginValue: number;
  totalMarginValue: number;
}

export interface CreateConsignetMarginBase {
  consignetRegistrationId: number;
  consignetPartnershipId: number;
  cpf: string;
}

export interface ConsignetPartnership {
  id: number;
  name: string;
}

export interface MarginBaseRegistersGroupedByCompany {
  id: number;
  marginBaseId: number;
  cpf: string;
  occupation: string;
  liquidIncome: string;
  selfDeclaredLiquidIncome: string;
  admissionDate: string;
  created_at: string;
  updated_at: string;
  deleted_at?: string;
  consignetRegistrationId?: number;
  consignetPartnershipId?: number;
  marginBase: {
    id: number;
    companyId: number;
    userId: number;
    fileName: string;
    storageKey: string;
    processingDate: string;
    created_at: string;
    updated_at: string;
    deleted_at?: string;
  };
  company: {
    id: number;
    name: string;
    cnpj: string;
  };
}
export interface ListMarginBaseParams {
  companyId: number;
  page: number;
  limit: number;
  search?: string;
  sort?: string;
}

export interface DeleteMarginBaseParams {
  marginBaseId: number;
}
export interface ListMarginBaseRegistersParams {
  marginBaseId: number;
  page: number;
  limit: number;
  search?: string;
  sort?: string;
}

export interface ListMarginCountForCompaniesParams {
  companiesIds: number[];
}

interface MarginBaseList {
  items: MarginBase[];
  total: number;
}
export interface GenerateMarginBaseReportXlsData {
  token: string;
}

export interface MarginBaseRegisterList {
  items: MarginBaseRegister[];
  total: number;
}

export type MarginBaseRegisterCountList = Array<{
  companyId: number;
  marginBaseRegisterCount: number;
}>;

export type MarginBaseRegisterFilterList = {
  page: number;
  limit: number;
  cpf?: string | null;
  startedDate?: string | null;
  endDate?: string | null;
  companiesIds?: string | null;
  marginIsActivated?: boolean | null;
};

export type MarginBaseRegisterCreateDTO = {
  companyId: number | null;
  registers: {
    cpf: string | null;
    liquidIncome: number | null;
    admissionDate: string | null;
    occupation: string | null;
    name: string | null;
    registration: number | null;
    marginBaseRegisterType: number | null;
  }[];
};
