
import { Vue, Component, Prop } from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import DatePicker from "@/components/DatePicker.vue";
import formatDatetime from "@/utils/formatDatetime";
import { format as formatCNPJ } from "@/utils/cnpj";
import { format } from "@/utils/cpf";
import formatCurrency from "@/utils/formatCurrency";
import CompanyService, {
  ListCompaniesResponse
} from "@/services/company-service";
import Company from "@/types/company";
import MarginBaseService, {
  MarginBaseRegisterCreateDTO
} from "@/services/margin-base-service";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { MarginBaseRegisterTypeEnum } from "@/types/MarginBaseRegisterTypeEnum";
import VTextFieldWithValidation from "../core/forms/VTextFieldWithValidation.vue";
import AlertBoxVue from "../AlertBox.vue";

@Component({
  components: {
    Modal,
    DatePicker,
    ValidationObserver,
    ValidationProvider,
    VTextFieldWithValidation,
    AlertBoxVue
  }
})
export default class MarginBasesCreateModal extends Vue {
  companyService: CompanyService;
  marginBaseService: MarginBaseService;
  selectedCompany: Partial<Company> | null = null;
  @Prop() readonly listOfCompanySelecteds?: ListCompaniesResponse;
  @Prop() readonly editMarginBaseData?: MarginBaseRegisterCreateDTO;
  companiesList: ListCompaniesResponse;
  loading: boolean = false;
  marginBaseRegisterType = null;
  loadingExcel: boolean = false;
  marginDataCreate: MarginBaseRegisterCreateDTO;
  formatCNPJ = formatCNPJ;
  formatCPF = format;
  formatCurrency = formatCurrency;
  formatDatetime = formatDatetime;
  $refs!: {
    obs: InstanceType<typeof ValidationObserver>;
  };

  constructor() {
    super();
    this.companyService = CompanyService.getInstance();
    this.marginBaseService = MarginBaseService.getInstance();
    this.marginDataCreate = {
      companyId: this.selectedCompany?.id ?? null,
      registers: []
    };
    this.companiesList = this.listOfCompanySelecteds ?? {
      items: [],
      total: 0
    };
    this.marginBaseRegisterType = {
      items: [
        {
          id: MarginBaseRegisterTypeEnum.CLT.id,
          name: MarginBaseRegisterTypeEnum.CLT.name
        },
        {
          id: MarginBaseRegisterTypeEnum.PJ.id,
          name: MarginBaseRegisterTypeEnum.PJ.name
        }
      ]
    };
  }

  created(): void {
    this.loadFilters();

    if (this.editMarginBaseData?.companyId) {
      const selectedCompany = this.companiesList.items.find(
        (company) => company.id === this.editMarginBaseData?.companyId
      );
      if (selectedCompany) {
        this.selectedCompany = selectedCompany;
        this.marginDataCreate = {
          companyId: selectedCompany.id,
          registers: this.editMarginBaseData.registers
            ? [
                {
                  ...this.editMarginBaseData.registers[0]
                }
              ]
            : []
        };
      }
    }
  }

  addNewMarginField(): void {
    const register = {
      cpf: null,
      liquidIncome: null,
      occupation: null,
      admissionDate: "",
      name: "",
      registration: null,
      marginBaseRegisterType: null
    };
    this.marginDataCreate.registers.push(register);
  }

  async onSave(): Promise<void> {
    const isValid = await this.$refs.obs.validate();
    if (!isValid) {
      this.$notify({
        type: "error",
        text: "Por favor, preencha todos os campos obrigatórios."
      });
      return;
    }

    const [marginError, marginData] =
      await this.marginBaseService.createManualMarginBaseRegister(
        this.marginDataCreate
      );
    if (marginError || !marginData) {
      this.$notify({
        type: "error",
        text: "Ocorreu um erro ao registrar a margem"
      });
    } else if (marginData) {
      this.$notify({
        type: "success",
        text: "margem registrada com sucesso!"
      });
      this.$emit("updated");
      this.$emit("close");
    }
  }

  async loadFilters(): Promise<void> {
    if (!this.companiesList.items) {
      const [companiesError, companiesData] =
        await this.companyService.listCompanies({
          page: 1,
          limit: -1,
          sort: "name:ASC",
          search: ""
        });
      if (companiesError) {
        // Handle error
      } else if (companiesData) {
        this.companiesList = companiesData;
      }
    }
  }

  updateCompanyFilters(): void {
    if (this.selectedCompany?.id) {
      this.marginDataCreate.companyId = this.selectedCompany?.id;
    }
  }

  removeRegisterAtIndex(index: number): void {
    if (this.marginDataCreate.registers?.length > 0) {
      this.marginDataCreate.registers.splice(index, 1);
    }
  }

  get isEditModal(): boolean {
    return !!this.editMarginBaseData;
  }

  get isConsignetCompany(): boolean {
    if (!this.selectedCompany) return false;

    if (this.selectedCompany.useConsignetPartnership) return true;

    return false;
  }

  get hasDuplicateCpfs(): boolean {
    const cpfs = this.marginDataCreate.registers.map(
      (register) => register.cpf
    );
    const uniqueCpfs = new Set(cpfs);
    return uniqueCpfs.size !== cpfs.length;
  }
}
