
import { Component, Vue } from "vue-property-decorator";
import BorrowerService from "@/services/borrower-service";
import { format as formatCPF } from "@/utils/cpf";
import Borrower from "../../../types/borrower";
import { debounce } from "debounce";

@Component
export default class SelectBorrower extends Vue {
  formatCPF = formatCPF;
  borrowerService: BorrowerService = BorrowerService.getInstance();
  loadingBorrowers = false;
  borrowersPage: number = 1;
  borrowersData: {
    items: Borrower[] & { borrowerNameOrCpf?: string };
    total: number;
  } = { items: [], total: 0 };
  borrowerNameOrCpf: string = "";
  selectedBorrower: Borrower | null;
  search: string | null;

  constructor() {
    super();

    this.borrowersPage = 1;
    this.selectedBorrower = null;
    this.search = null;
  }

  mounted(): void {
    this.fetchMoreBorrowers({});
  }

  async endIntersect(_entries: any, _observer: any, isIntersecting: boolean) {
    if (isIntersecting) {
      await this.fetchMoreBorrowers({});
    }
  }

  get fetchMoreBorrowers() {
    return debounce(async ({ reset }: { reset?: boolean }) => {
      await this._fetchMoreBorrowers({ reset });
    }, 1000);
  }

  async _fetchMoreBorrowers({ reset }: { reset?: boolean }): Promise<void> {
    if (reset) {
      this.borrowersPage = 1;
    }

    const thereAreMoreBorrowersToFetch =
      this.borrowersPage === 1 ||
      this.borrowersData.total > this.borrowersData.items.length;

    if (thereAreMoreBorrowersToFetch) {
      this.loadingBorrowers = true;

      let [borrowersError, borrowersData] = await this.borrowerService.list({
        page: this.borrowersPage,
        limit: 50,
        borrowerNameOrCpf: this.search ?? undefined,
        withCompanies: false
      });

      if (borrowersError) {
        return this.$notify({
          type: "error",
          text: "Não foi possível carregar a lista de tomadores para o filtro."
        });
      }
      this.borrowersPage += 1;

      const _borrowersData = borrowersData!.data.map((borrower) => {
        return {
          ...borrower,
          borrowerNameOrCpf: `${borrower.name} - ${formatCPF(borrower.cpf)}`
        };
      });

      if (this.search) {
        this.borrowersData.items = _borrowersData;
        this.borrowersData.total = borrowersData.total;
      } else {
        this.borrowersData.items.push(..._borrowersData);
        this.borrowersData.total = borrowersData.total;
      }

      this.loadingBorrowers = false;
    }
  }

  emitValue(value) {
    this.$emit("input", value?.id);
  }

  formatBorrowerSearch(borrower: Borrower): string {
    return borrower.name + "-" + formatCPF(borrower.cpf);
  }

  async _asyncSetSearch(value: any) {
    await this.$nextTick();
    this.search = value;
  }
}
