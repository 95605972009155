import HttpClient, { Response } from "./http-client";
import Company from "@/types/company";
import Group from "@/types/group";
import CompanyRegistrationCompanyData from "@/types/company-registration-company-data";
import Partnership from "@/types/partnership";
import CompanyRepresentative from "@/types/company-representative";
import CompanyDocument from "@/types/company-document";
import CompanyCreate from "@/types/company-create";
import CompanyStatus from "@/types/company-status";
import CompanyObservation from "../types/company-observation";
import { DeleteResult } from "./partner-service";
import Products from "@/types/products";

export default class CompanyService extends HttpClient {
  private static classInstance?: CompanyService;

  private constructor() {
    super(process.env.VUE_APP_COMPANY_URL!);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new CompanyService();
    }

    return this.classInstance;
  }

  public async registerCompany(
    data: RegisterCompanyParams
  ): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "POST",
      url: "/companies/register",
      data: data
    });
  }
  public async getCompanyRegisterData(): Promise<
    Response<CompanyRegisterDataResponse>
  > {
    return this.axiosCall<CompanyRegisterDataResponse>({
      method: "GET",
      url: "/companies/get-register-data"
    });
  }

  public async updateRegisterData({
    id,
    ...data
  }: UpdateRegisterDataParams): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "PATCH",
      url: `/companies/${id}/update-register-data`,
      data: data
    });
  }

  public async createOrUpdateCompanyRepresentatives(
    data: CreateOrUpdateCompanyRepresentativesData
  ): Promise<Response<CompanyRepresentative[]>> {
    return this.axiosCall<CompanyRepresentative[]>({
      method: "POST",
      url: "/company-representatives/create-or-update",
      data: data
    });
  }

  public async listCompanyRepresentativesForCompany(
    companyId: number
  ): Promise<Response<CompanyRepresentative[]>> {
    return this.axiosCall<CompanyRepresentative[]>({
      method: "GET",
      url: `/company-representatives/list-for-company/${companyId}`
    });
  }

  public async getDocumentSignatureKeyForCurrentUser(
    data: GetDocumentSignatureKeyForCurrentUserData
  ): Promise<Response<string | undefined>> {
    return this.axiosCall<string | undefined>({
      method: "GET",
      url: "/company-representatives/get-document-signature-key-for-current-user",
      params: data
    });
  }

  public async createCompany(data: CompanyCreate): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "POST",
      url: "/companies/create",
      data: data
    });
  }

  public async listCompanies(
    options: ListCompaniesParams
  ): Promise<Response<ListCompaniesResponse>> {
    return this.axiosCall<ListCompaniesResponse>({
      method: "GET",
      url: "/companies/list",
      params: options
    });
  }

  public async listCompanyDocuments(
    companyId: number
  ): Promise<Response<CompanyDocument[]>> {
    return this.axiosCall<CompanyDocument[]>({
      method: "GET",
      url: `/documents/list/${companyId}`
    });
  }

  public async createOrUpdateCompanyDocument(data: {
    companyId: number;
    type: string;
    url: string;
    companyRepresentativeId?: number;
  }): Promise<Response<CompanyDocument>> {
    return this.axiosCall<CompanyDocument>({
      method: "POST",
      url: `/documents/create`,
      data
    });
  }

  public async getCompany(id: number): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "GET",
      url: `/companies/get/${id}`
    });
  }

  public async deleteCompany(id: number): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "DELETE",
      url: `/companies/delete/${id}`
    });
  }

  public async updateCompany(
    id: number,
    data: {
      groupId: number;
      name: string;
      cnpj: string;
      segmentName: string;
      segmentId: number;
      creditPolicyId?: number | null;
      zipCode: string;
      address: string;
      number: string;
      complement: string;
      district: string;
      city: string;
      state: string;
      phone: string;
      payCutDay?: string;
      expirationDay?: string;
      paymentDay?: string;
      gracePeriodForExpirationDay?: string;
      useConsignetPartnership?: boolean | null;
      consignetPartnershipId?: number | null;
      useAutomaticEndorsement?: boolean | null;
      observations: string[];
      batchAnticipationDate?: string | null;
    }
  ): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "PUT",
      url: `/companies/update/${id}`,
      data: data
    });
  }

  public async createGroup(data: {
    name: string;
    admin_name?: string | null;
    admin_email?: string | null;
    admin_password?: string | null;
    admin_phone?: string | null;
  }): Promise<Response<Group>> {
    return this.axiosCall<Group>({
      method: "POST",
      url: "/groups/create",
      data: data
    });
  }

  public async listGroups(pagination: {
    page: number;
    limit: number;
    sort: string;
    groupName: string;
    companyNameOrCnpj: string;
    adminEmail: string;
    adminPhone: string;
  }): Promise<any> {
    return this.axiosCall<Group>({
      method: "GET",
      url: "/groups/list",
      params: pagination
    });
  }

  public async inactivateGroup(groupId: number): Promise<any> {
    return this.axiosCall<Group>({
      method: "PUT",
      url: `/groups/inactivate/${groupId}`
    });
  }

  public async activateGroup(groupId: number): Promise<any> {
    return this.axiosCall<Group>({
      method: "PUT",
      url: `/groups/activate/${groupId}`
    });
  }

  public async deleteGroup(groupId: number): Promise<Response<DeleteResult>> {
    return this.axiosCall<DeleteResult>({
      method: "DELETE",
      url: `/groups/delete/${groupId}`
    });
  }

  public async getGroup(id: number): Promise<Response<Group>> {
    return this.axiosCall<Group>({ method: "GET", url: `/groups/get/${id}` });
  }

  public async updateGroup(
    id: number,
    data: { name: string }
  ): Promise<Response<Group>> {
    return this.axiosCall<Group>({
      method: "PUT",
      url: `/groups/update/${id}`,
      data: data
    });
  }

  public async activateCompany(id: number): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "PUT",
      url: `/companies/activate/${id}`
    });
  }

  public async updateCompanyStatus(
    id: number,
    data: { status: string; notes: string }
  ): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "POST",
      url: `/companies/${id}/update-status`,
      data: data
    });
  }

  public async getCompanyStatusHistory(
    id: number
  ): Promise<Response<StatusHistory[]>> {
    return this.axiosCall<StatusHistory[]>({
      method: "GET",
      url: `/companies/${id}/status-history`
    });
  }

  public async blockCompany(id: number): Promise<Response<Company>> {
    return this.axiosCall<Company>({
      method: "PUT",
      url: `/companies/block/${id}`
    });
  }

  public async checkIfAdminGroupHasFinishedCompanyRegister(
    adminGroupId: number
  ): Promise<Response<boolean>> {
    return this.axiosCall<boolean>({
      method: "GET",
      url: `/users-companies/check-admin-group-has-finished-company-register/${adminGroupId}`
    });
  }

  public async listObservations(): Promise<Response<ListObservationsResponse>> {
    return this.axiosCall<ListObservationsResponse>({
      method: "GET",
      url: "/companies/observations/list"
    });
  }

  public async findProductsByCompanies(
    companiesIds: number[]
  ): Promise<Response<FindProductsByCompaniesResponse>> {
    return this.axiosCall<FindProductsByCompaniesResponse>({
      method: "GET",
      url: `/products/list-by-companies`,
      params: {
        companiesIds: companiesIds.join(",")
      }
    });
  }

  public async findProductByCompanyAndType(params: {
    companyId: number;
    typeId: number;
  }): Promise<Response<Products>> {
    return this.axiosCall<Products>({
      method: "GET",
      url: `/products/get-by-company-type`,
      params
    });
  }

  public async updateProductsByCompany(
    data: UpdateProductsByCompanyData
  ): Promise<Response<Products[]>> {
    return this.axiosCall<Products[]>({
      method: "POST",
      url: `/products/update-by-company`,
      data
    });
  }
}

export interface StatusHistory {
  id: number;
  status: CompanyStatus;
  notes: string;
  date: string;
}

export interface ListCompaniesParams {
  userId?: number;
  groupId?: number;
  statusId?: number;
  partner?: number;
  initialDate?: string;
  endDate?: string;
  page: number;
  limit: number;
  search?: string;
  sort?: string;
}

export interface ListCompaniesResponse {
  items: Company[];
  total: number;
}

type RegisterCompanyParams = CompanyRegistrationCompanyData;

interface CompanyRegisterDataResponse {
  company: Company;
  partnership: Partnership;
}

export type UpdateRegisterDataParams = CompanyRegistrationCompanyData & {
  id: number;
};

interface CreateOrUpdateCompanyRepresentativesData {
  companyId: number;
  companyRepresentatives: {
    type: string;
    name: string;
    cpf: string;
    phone: string;
    email: string;
    birthDate: string;
  }[];
}

interface GetDocumentSignatureKeyForCurrentUserData {
  companyId: number;
}

interface UpdateProductsByCompanyData {
  useCustomProducts: boolean;
  companyId: number;
  applyOnGroup?: boolean;
  groupId?: number;
  productList: {
    isActive: boolean;
    typeId: number;
    bankingId: number;
    adminId: number;
    fundId: number;
  }[];
}

type ListObservationsResponse = CompanyObservation[];

type FindProductsByCompaniesResponse = {
  [companyId: number]: Array<Partial<Products>>;
};
