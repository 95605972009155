export default interface PaymentType {
  id: number;
  name: string;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

export enum PaymentTypeEnum {
  BOLETO_BANCARIO = 1,
  OUTROS = 2
}
