export function validateDayInInterval({
  day,
  min,
  max
}: {
  day: string;
  min: number;
  max: number;
}): boolean {
  if (day === "") return false;

  const dayNumber = Number(day);
  if (dayNumber < min || dayNumber > max) return false;

  return true;
}
