import Vue from 'vue'
import VueI18n from 'vue-i18n';
import main from '@/locales/main';

Vue.use(VueI18n);

export default new VueI18n({
    locale: 'ptBR',
    messages: main
});
