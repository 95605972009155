
import { Vue, Component, Prop } from "vue-property-decorator";

@Component
export default class Modal extends Vue {
  @Prop({ default: false }) show!: boolean;
  @Prop({ default: false }) persistent!: boolean;
  @Prop({ default: false }) fullscreen!: boolean;
  @Prop({ default: "" }) readonly title!: string;
  @Prop({ default: "80%" }) readonly maxWidth!: string;

  changed(value: boolean) {
    if (!value) {
      this.$emit("close");
    }
  }
}
