import { render, staticRenderFns } from "./AlertBox.vue?vue&type=template&id=7207f634&scoped=true"
import script from "./AlertBox.vue?vue&type=script&lang=js"
export * from "./AlertBox.vue?vue&type=script&lang=js"
import style0 from "./AlertBox.vue?vue&type=style&index=0&id=7207f634&prod&lang=scss"
import style1 from "./AlertBox.vue?vue&type=style&index=1&id=7207f634&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7207f634",
  null
  
)

export default component.exports