import HttpClient, { Response } from "./http-client";
import Borrower from "@/types/borrower";
import { PartnerFilters } from "./partner-service";
import User from "../types/user";

export interface ListBorrowersResponse {
  items: Borrower[];
  total: number;
}
export default class BorrowerService extends HttpClient {
  private static classInstance?: BorrowerService;

  private constructor() {
    super(process.env.VUE_APP_BORROWER_URL!);
  }

  public static getInstance() {
    if (!this.classInstance) {
      this.classInstance = new BorrowerService();
    }

    return this.classInstance;
  }

  public async getBorrower(id: number): Promise<Response<Borrower>> {
    return this.axiosCall<Borrower>({
      method: "GET",
      url: `/borrowers/get/${id}`
    });
  }

  public async getBorrowerByUserId(
    userId: number
  ): Promise<Response<Borrower>> {
    return this.axiosCall<Borrower>({
      method: "GET",
      url: `/borrowers/get-by-user-id/${userId}`
    });
  }

  public async getBorrowerByCpf(cpf: string): Promise<Response<Borrower>> {
    return this.axiosCall<Borrower>({
      method: "GET",
      url: `/borrowers/get-by-cpf/${cpf}`
    });
  }

  public async generateBorrowersReportXls(
    params: GenerateBorrowersReportXlsParams
  ): Promise<any> {
    return this.axiosCall<GenerateBorrowersReportXlsResponse>({
      method: "POST",
      url: `/reports/borrowers/generate-xls-token`,
      params
    });
  }

  public async list(
    pagination: BorrowerFilters
  ): Promise<Response<BorrowerList>> {
    return this.axiosCall<BorrowerList>({
      method: "GET",
      url: `/borrowers/list`,
      params: pagination
    });
  }

  public async listStatus(): Promise<any[]> {
    return this.axiosCall<any[]>({
      method: "GET",
      url: `/borrower-status/list`
    });
  }

  public async update(
    borrowerId: number,
    params: Partial<Borrower>
  ): Promise<Response<void>> {
    return this.axiosCall<void>({
      method: "PATCH",
      url: `/borrowers/update/${borrowerId}`,
      data: params
    });
  }

  public async updateCreditPolicy(
    borrowerId: number,
    params: {
      creditPolicyId: number;
      creditPolicyStatus: boolean;
      inicialCreditPolicyDueDate: Date;
      finalCreditPolicyDueDate: Date;
    }
  ): Promise<Response<Borrower>> {
    return this.axiosCall<Borrower>({
      method: "PATCH",
      url: `/borrowers/update-credit-policy/${borrowerId}`,
      data: params
    });
  }

  public async getLoanBorrowersByCpf(
    borrowerCpf: string
  ): Promise<Response<Borrower>> {
    return this.axiosCall<Borrower>({
      method: "GET",
      url: `/borrowers/get-by-cpf/${borrowerCpf}`
    });
  }
}

export interface CreateBorrowerParams extends Omit<Borrower, "signingMethod"> {
  user?: Omit<User, "blacklistedCommunications">;
}

export interface BorrowerList {
  data: Borrower[];
  total: number;
}
export interface BorrowerFilters {
  sort?: string;
  name?: string;
  cpf?: string;
  statusId?: number | null;
  companyId?: number | null;
  withCompanies: boolean;
  page?: number;
  limit?: number;
  borrowerNameOrCpf?: string | null;
}

interface GenerateBorrowersReportXlsParams {
  name?: string;
  cpf?: string;
  statusId?: number | null;
  companyId?: number | null;
  borrowerNameOrCpf?: string | null;
}

interface GenerateBorrowersReportXlsResponse {
  token: string;
}
