
import { Vue, Component } from "vue-property-decorator";

@Component
export default class Breadcrumb extends Vue {
  constructor() {
    super();
  }

  get breadCrumbs() {
    const pathArray = this.$route.path.split("/");
    pathArray.shift();

    const breadCrumbs = [];
    let breadcrumb = "";
    let lastIndexFound = 0;

    for (let i = 0; i < pathArray.length; ++i) {
      breadcrumb = `${breadcrumb}${"/"}${pathArray[i]}`;
      if (
        this.$route.matched[i] &&
        Object.hasOwnProperty.call(this.$route.matched[i], "meta") &&
        Object.hasOwnProperty.call(this.$route.matched[i].meta, "breadCrumb")
      ) {
        breadCrumbs.push({
          href:
            i !== 0 && pathArray[i - (i - lastIndexFound)]
              ? `/${pathArray[i - (i - lastIndexFound)]}${breadcrumb}`
              : breadcrumb,
          disabled: i + 1 === pathArray.length,
          text: this.$route.matched[i].meta.breadCrumb || pathArray[i],
        });
        lastIndexFound = i;
        breadcrumb = "";
      }
    }
    return breadCrumbs;
  }
}
