
import { Vue, Component, Watch, Prop } from "vue-property-decorator";
import DebtAgreementService from "@/services/debt-agreement-service";
import DebtAgreementInstallmentsService, {
  InstallmentsDebtAgreementFilters,
  ListInstallmentsDebtAgreementsResponseDto
} from "@/services/installments-debt-agreement-service";
import {
  DebtAgreementStatus,
  DebtAgreementStatusEnum
} from "@/types/debt-agreement-status";
import { DataOptions, DataTableHeader } from "vuetify";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import formatCurrency from "../../utils/formatCurrency";
import formatDatetime from "@/utils/formatDatetime";
import { DebtAgreementInstallment } from "@/types/debt-agreement-installment";
import { DebtAgreementInstallmentStatusEnum } from "@/types/debt-agreement-installments-status";
import { DebtAgreement } from "@/types/debt-agreement";
import DatePicker from "../DatePicker.vue";
import PaymentService from "@/services/payment-service";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import Borrower from "@/types/borrower";
import BorrowerService from "@/services/borrower-service";
import SelectBorrower from "../core/dashboard/SelectBorrower.vue";

@Component({
  components: {
    DatePicker,
    SelectBorrower
  }
})
export default class DebtAgreementsInstallmentsTable extends Vue {
  @Prop({ default: { data: [], total: 0 } })
  readonly debtAgreementsInstallments!: ListInstallmentsDebtAgreementsResponseDto;
  @Prop() fetch!: (options: InstallmentsDebtAgreementFilters) => Promise<void>;
  @Prop() refresh!: number;
  borrowers: Borrower[];
  loadingBorrowers = false;
  service: DebtAgreementInstallmentsService =
    DebtAgreementInstallmentsService.getInstance();
  borrowerService: BorrowerService = BorrowerService.getInstance();

  formatCPF = formatCPF;
  formatDate = formatDate;
  formatDatetime = formatDatetime;
  formatCurrency = formatCurrency;
  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();
  paymentService: PaymentService = PaymentService.getInstance();
  DebtAgreementInstallmentStatusEnum = DebtAgreementInstallmentStatusEnum;
  DebtAgreementStatusEnum = DebtAgreementStatusEnum;
  selectedDebtAgreement: DebtAgreement | null = null;
  showDebtAgreementModal = false;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  filters: InstallmentsDebtAgreementFilters = {
    page: 1,
    limit: 10
  };
  loadingXls = false;
  dateFilterTypes: Array<string> = ["Data acordo", "Data Vencimento"];
  dateFilterType = "Data acordo";
  statusList: DebtAgreementStatus[] | null = null;
  loading: boolean;
  options: DataOptions;
  headers: Array<DataTableHeader> = [
    {
      text: "Data Acordo",
      value: "debtAgreement.createdAt",
      sortable: false,
      width: "9%"
    },
    { text: "Acordo", value: "debtAgreementId", sortable: true, width: "5%" },
    {
      text: "Contrato",
      value: "debtAgreement.loanProposalNumber",
      sortable: false,
      width: "8%"
    },
    {
      text: "Valor Acordo",
      value: "debtAgreement.negotiatedValue",
      sortable: false,
      width: "8%"
    },
    {
      text: "Valor Parcela",
      value: "debtAgreement.installmentValue",
      sortable: false,
      width: "8%"
    },
    {
      text: "Data vencimento",
      value: "dueDate",
      sortable: false,
      width: "10%"
    },
    {
      text: "Dias Atraso",
      value: "lateDays",
      sortable: false,
      width: "10%"
    },
    { text: "Status", value: "status", sortable: false, width: "8%" },
    {
      text: "Pagador",
      value: "debtAgreement.borrowerCpf",
      sortable: false,
      width: "15%"
    },
    {
      text: "Parcela/Prazo",
      value: "debtAgreement.numInstallments",
      sortable: false,
      width: "3%",
      align: "center",
      class: "text-center"
    },
    { text: "Ações", value: "actions", sortable: false, width: "50px" }
  ];

  constructor() {
    super();
    this.loading = false;
    this.options = {} as DataOptions;
    this.borrowers = [];
  }

  mounted(): void {
    this.loadFilters();
  }

  async loadFilters(): Promise<void> {
    this.fetchDebtAgreementInstallmentsStatusList();
  }

  async fetchDebtAgreementInstallmentsStatusList(): Promise<void> {
    const [statusListError, statusList] =
      await this.service.listDebtAgreementInstallmentsStatusList();

    if (!statusListError) {
      this.statusList = statusList;
    } else {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status para o filtro."
      });
    }
  }

  @Watch("options")
  onOptionsChange(val: DataOptions): void {
    this.fetchDebtAgreementsInstallments(val);
  }

  async fetchDebtAgreementsInstallments(val: DataOptions): Promise<void> {
    const { page, itemsPerPage, sortBy, sortDesc } = val;
    const attr = sortBy[0];
    const order = sortDesc[0] ? "DESC" : "ASC";
    this.startLoading();
    await this.fetch({
      ...this.filters,
      page: page,
      limit: itemsPerPage,
      sort: attr ? `${attr}:${order}` : undefined,
      loadRelations: true
    });
    this.stopLoading();
  }
  applyFilters(): void {
    this.filters.page = 1;
    this.fetchDebtAgreementsInstallments(this.options);
  }

  handleShowDebtAgreement(debtAgreement: DebtAgreement): void {
    this.selectedDebtAgreement = debtAgreement;
    this.showDebtAgreementModal = true;
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.createdAtStart = undefined;
    this.filters.createdAtEnd = undefined;
    this.filters.dueDateStart = undefined;
    this.filters.dueDateEnd = undefined;
  }

  async generatePdf(installment: DebtAgreementInstallment) {
    if (
      installment.statusId === DebtAgreementInstallmentStatusEnum.PENDING.id
    ) {
      const confirmed = window.confirm(
        "A Parcela selecionada não está registrada. Deseja realizar o Registro?"
      );
      if (confirmed) {
        const [error, generated] =
          await this.service.generateBankSlipForInstallment({
            id: installment.id
          });
        if (!error && generated) {
          const [error, data] =
            await this.paymentService.findDebtAgreementPayment({
              debtAgreementInstallmentId: installment.id,
              debtAgreementId: installment.debtAgreementId
            });

          if (!error && data) {
            const downloadUrl =
              this.fileManagerUrl +
              "/download/" +
              data?.payment.paymentBankSlip.storageKey;
            window.open(downloadUrl, "_blank");
            this.applyFilters();
          } else {
            this.$notify({
              type: "error",
              text: getErrorMessageFromApiError(error)
            });
          }
        } else {
          this.$notify({
            type: "error",
            text: getErrorMessageFromApiError(error)
          });
        }
      }
    } else {
      const [error, data] = await this.paymentService.findDebtAgreementPayment({
        debtAgreementInstallmentId: installment.id,
        debtAgreementId: installment.debtAgreementId
      });

      if (!error && data) {
        const downloadUrl =
          this.fileManagerUrl +
          "/download/" +
          data?.payment.paymentBankSlip.storageKey;
        window.open(downloadUrl, "_blank");
      } else {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
      }
    }
  }

  @Watch("refresh")
  onRefreshChange(): void {
    this.options = { ...this.options };
  }

  closeDebtAgreement(): void {
    this.showDebtAgreementModal = false;
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] = await this.service.generateDebtAgreementsReportXls(
      this.filters
    );
    if (!error) {
      window.open(
        process.env.VUE_APP_DEBT_AGREEMENT_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }
}
