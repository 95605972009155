/** Validates a landline phone number and returns an error message, or an empty string otherwise.
 * Adapted from https://gist.github.com/jonathangoncalves/7bdec924e9bd2bdf353d6b7520820b62 */
export default function getErrorMessageForLandlineOrMobilePhoneValidation(
  phone: string
) {
  const validDDDs = [
    11, 12, 13, 14, 15, 16, 17, 18, 19, 21, 22, 24, 27, 28, 31, 32, 33, 34, 35,
    37, 38, 41, 42, 43, 44, 45, 46, 47, 48, 49, 51, 53, 54, 55, 61, 62, 64, 63,
    65, 66, 67, 68, 69, 71, 73, 74, 75, 77, 79, 81, 82, 83, 84, 85, 86, 87, 88,
    89, 91, 92, 93, 94, 95, 96, 97, 98, 99
  ];

  // Expected formatting: (00) 00000-0000 for mobile phone or (00) 0000-0000 for landline

  if (phone.length !== 15 && phone.length !== 14)
    return "Digite um número de telefone completo";

  const openingParenthesis = phone.substring(0, 1);
  const ddd = phone.substring(1, 3);
  const closingParenthesis = phone.substring(3, 4);
  const blankSpace = phone.substring(4, 5);
  const isMobilePhone = phone.length === 15;
  let numberFirstPart, hiphen, numberSecondPart;
  if (isMobilePhone) {
    numberFirstPart = phone.substring(5, 10);
    hiphen = phone.substring(10, 11);
    numberSecondPart = phone.substring(11, 15);
  } else {
    numberFirstPart = phone.substring(5, 9);
    hiphen = phone.substring(9, 10);
    numberSecondPart = phone.substring(10, 14);
  }

  if (openingParenthesis !== "(")
    return "Digite um número de telefone completo";
  if (closingParenthesis !== ")")
    return "Digite um número de telefone completo";
  if (blankSpace !== " ") return "Digite um número de telefone completo";
  if (hiphen !== "-") return "Digite um número de telefone completo";

  if (
    isNaN(Number(ddd)) ||
    isNaN(Number(numberFirstPart)) ||
    isNaN(Number(numberSecondPart))
  )
    return "Digite apenas números";

  if (!validDDDs.includes(Number(ddd))) return "Digite um DDD válido";

  if (isMobilePhone) {
    if (numberFirstPart[0] !== "9")
      return "Telefone celular deve iniciar com 9";

    const numberFirstPartMinusNine = numberFirstPart.substring(1, 5);
    if (numberFirstPartMinusNine[0] === "0")
      return "Digite um número de telefone real";
  } else {
    if (!["2", "3", "4", "5"].includes(numberFirstPart[0]))
      return "Digite um número de telefone real";
  }

  const equalCharactersRegex = /^(.)\1+$/;
  if (equalCharactersRegex.test(numberFirstPart.concat(numberSecondPart)))
    return "Digite um número de telefone real";

  return "";
}
