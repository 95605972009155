
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { ValidationProvider } from "vee-validate";

@Component({ directives: { mask }, components: { ValidationProvider } })
export default class DatePicker extends Vue {
  @Prop(String) readonly value!: string;
  @Prop([Object, String]) readonly rules?: any;
  @Prop({ default: "Data" }) readonly label!: string;
  @Prop({ default: "" }) readonly min!: string;
  @Prop({ default: "" }) readonly max!: string;
  @Prop({ default: false }) readonly rounded!: boolean;
  @Prop({ default: false }) readonly outlined!: boolean;
  @Prop({ default: false }) readonly required!: boolean;

  @Prop() readonly hideDetails!: string | boolean;

  date = "";
  dateFormatted = "";

  menu1 = false;
  menu2 = false;

  constructor() {
    super();
    if (this.value) {
      this.date = this.value;
      this.updateDateFormatted(this.value);
    }
  }

  @Watch("date")
  updateDateFormatted(newValue: string): void {
    this.$nextTick(() => {
      this.dateFormatted = this.formatDate(newValue);
    });
    this.$emit("input", newValue);
  }

  @Watch("value")
  updateValue(newValue: string): void {
    this.date = newValue;
    this.updateDateFormatted(newValue);
  }

  get computedDateFormatted(): string {
    return this.formatDate(this.date);
  }

  formatDate(date: string): string {
    if (!date) return "";

    const [year, month, day] = date.split("-");
    return `${day}/${month}/${year}`;
  }

  parseDate(date: string): string {
    if (!date) return "";

    const [day, month, year] = date.split("/");
    return `${year}-${month.padStart(2, "0")}-${day.padStart(2, "0")}`;
  }

  get minDate() {
    if (this.min) {
      return new Date(this.min).toISOString();
    }
    return undefined;
  }

  get maxDate() {
    if (this.max) {
      return new Date(this.max).toISOString();
    }
    return undefined;
  }
}
