
import MarginBase from "@/types/margin-base";
import debounce from "debounce";
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import { ListMarginBaseParams } from "../../services/margin-base-service";
import { MarginBaseTypeEnum } from "@/types/margin-base-type";
import store from "@/store";

interface MarginBaseActions {
  registers?: boolean;
  manage?: boolean;
  delete?: boolean;
}

@Component
export default class MarginBasesTable extends Vue {
  @Prop({ default: [] }) readonly marginBases!: {
    items: Array<MarginBase>;
    total: number;
  };
  @Prop() fetch!: (options: ListMarginBaseParams) => Promise<void>;
  @Prop() refresh!: number;

  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;

  options: DataOptions;
  headers: Array<DataTableHeader>;
  search: string;
  loading: boolean;
  MarginBaseTypeEnum = MarginBaseTypeEnum;

  constructor() {
    super();
    this.headers = [
      { text: "Arquivo", value: "fileName", sortable: true },
      { text: "Data", value: "processingDate", sortable: true },
      { text: "Ações", value: "actions", sortable: false }
    ];
    this.search = "";
    this.loading = false;
    this.options = {} as DataOptions;
  }

  @Watch("options")
  onOptionsChange(val: DataOptions) {
    this.fetchMarginBases(val);
  }

  async fetchMarginBases(val: DataOptions) {
    const { page, itemsPerPage, sortBy, sortDesc } = val;

    this.startLoading();
    await this.fetch({
      companyId: this.companyId,
      page: page,
      limit: itemsPerPage,
      search: this.search,
      sort: this.handleSort(sortBy, sortDesc)
    });
    this.stopLoading();
  }

  @Watch("refresh")
  onRefreshChange() {
    this.options = { ...this.options };
  }

  @Watch("search")
  onSearchChanged(val: string) {
    this.startLoading();
    const { itemsPerPage, sortBy, sortDesc, page } = this.options;
    this.searchFunction(
      page,
      itemsPerPage,
      val,
      this.handleSort(sortBy, sortDesc)!
    );
  }

  get searchFunction() {
    return debounce(
      async (
        page: number,
        itemsPerPage: number,
        search: string,
        sort: string
      ) => {
        await this.fetch({
          companyId: this.companyId,
          page: page,
          limit: itemsPerPage,
          search: search,
          sort: sort
        });
        this.stopLoading();
      },
      700
    );
  }

  get companyId() {
    return store.getters["auth/currentUserCompanyId"];
  }

  emitAction(item: MarginBase, operation: MarginBaseActions) {
    if (this.marginBases.items.indexOf(item) > -1)
      item.index = this.marginBases.items.indexOf(item);
    this.$emit("action", { item, ...operation });
  }

  handleSort(sortBy: Array<string>, sortDesc: Array<boolean>) {
    const attr = sortBy[0] ?? "created_at";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return attr ? `${attr}:${order}` : undefined;
  }

  startLoading() {
    this.loading = true;
  }

  stopLoading() {
    this.loading = false;
  }
}
