export interface DebtAgreementInstallmentStatus {
  id: number;
  name: string;
  description: string;
}

const openStatus: DebtAgreementInstallmentStatus =
  {} as DebtAgreementInstallmentStatus;
openStatus.id = 1;
openStatus.name = "EM_ABERTO";
openStatus.description = "Em aberto";

const paidStatus: DebtAgreementInstallmentStatus =
  {} as DebtAgreementInstallmentStatus;
paidStatus.id = 2;
paidStatus.name = "PAGA";
paidStatus.description = "Paga";

const canceledStatus: DebtAgreementInstallmentStatus =
  {} as DebtAgreementInstallmentStatus;
canceledStatus.id = 3;
canceledStatus.name = "CANCELADA";
canceledStatus.description = "Cancelada";

export const DebtAgreementInstallmentStatusEnum = {
  OPEN: openStatus,
  PAID: paidStatus,
  CANCELED: canceledStatus
};
