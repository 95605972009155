import axios, {
  AxiosInstance,
  AxiosRequestConfig,
  AxiosResponse,
  InternalAxiosRequestConfig
} from "axios";
import { EventBus } from "@/events/event-bus";
import store from "@/store";
import jwt from "jsonwebtoken";
import axiosRetry from "axios-retry";

let requests = 0;
let hasRequestedRefreshToken = false; // used to avoid duplicate calls to refresh-token endpoint, since that call will also go through _handleRequest()

export default class HttpClient {
  private readonly instance: AxiosInstance;
  private baseUrl: string;

  constructor(baseUrl: string) {
    this.baseUrl = baseUrl;
    this.instance = axios.create({
      baseURL: this.baseUrl
    });
    axiosRetry(this.instance, { retries: 3 });

    this._initializeRequestInterceptor();
    this._initializeResponseInterceptor();
  }

  private _initializeRequestInterceptor = () => {
    this.instance.interceptors.request.use(
      this._handleRequest,
      this._handleErrorRequest
    );
  };

  private _initializeResponseInterceptor = () => {
    this.instance.interceptors.response.use(
      this._handleResponse,
      this._handleErrorResponse
    );
  };

  private _handleRequest = async (config: InternalAxiosRequestConfig) => {
    EventBus.$emit("requests", ++requests);

    let token = store.getters["auth/token"];

    // Check if token is expired

    let tokenIsExpired = true;
    const payload = jwt.decode(token);
    if (payload != null) {
      const exp = (payload as any)["exp"];
      const now = Math.floor(Date.now() / 1000);
      tokenIsExpired = exp <= now;
    }

    // If token is expired and this is not already a refresh-token request,
    // we refresh the token

    if (tokenIsExpired && !hasRequestedRefreshToken) {
      hasRequestedRefreshToken = true;
      const user = store.getters["auth/authenticatedUser"];
      const email = user.email;
      const refreshToken = store.getters["auth/refreshToken"];

      await store.dispatch("auth/refreshToken", {
        email: email,
        refreshToken: refreshToken
      });
    }

    hasRequestedRefreshToken = false;
    token = store.getters["auth/token"];
    config.headers!["Authorization"] = `Bearer ${token}`;

    return config;
  };

  // TODO: Implement refresh token interceptor
  private _handleResponse = ({ data }: AxiosResponse) => {
    EventBus.$emit("requests", --requests);
    return data;
  };

  protected _handleErrorRequest = (error: any) => {
    EventBus.$emit("requests", --requests);
    return Promise.reject(error);
  };

  protected _handleErrorResponse = async (error: any) => {
    EventBus.$emit("requests", --requests);
    return Promise.reject(error);
  };

  // private _handleTokenExpired = async () => {
  //   await this.instance.post('/refresh-token', {
  //     email: '',
  //     refreshToken:
  //   })
  // }

  protected async axiosCall<T>(
    config: AxiosRequestConfig
  ): Promise<Response<T>> {
    try {
      const data = (await this.instance.request<T>(config)) as unknown as T;
      return [null, data];
    } catch (err) {
      return [err, null];
    }
  }
}

export type Response<T> = [any | null, T | null];
