
import Page from "@/components/core/dashboard/Page.vue";
import MarginBaseDeleteModal from "@/components/margin-bases/MarginBaseDeleteModal.vue";
import MarginBaseManageModal from "@/components/margin-bases/MarginBaseManageModal.vue";
import MarginBaseRegistersTableModal from "@/components/margin-bases/MarginBaseRegistersTableModal.vue";
import MarginBasesTable from "@/components/margin-bases/MarginBasesTable.vue";
import MarginBaseService from "@/services/margin-base-service";
import ManageEvent from "@/types/manage-event";
import MarginBase from "@/types/margin-base";
import { Component, Vue, Watch } from "vue-property-decorator";
import {
  ListMarginBaseParams,
  ListMarginBaseRegistersParams
} from "../services/margin-base-service";
import store from "@/store";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";

@Component({
  components: {
    Page,
    MarginBasesTable,
    MarginBaseManageModal,
    MarginBaseDeleteModal,
    MarginBaseRegistersTableModal
  }
})
export default class MarginBases extends Vue {
  showManageModal: boolean;
  showDeleteModal: boolean;
  showMarginBaseRegistersModal: boolean;
  defaultItem: MarginBase = { index: null } as MarginBase;
  defaultReturn = { items: [], total: 0 };

  item: MarginBase;
  service: MarginBaseService;
  marginBases: { items: Array<MarginBase>; total: number };
  marginBaseRegisters: { items: Array<MarginBase>; total: number };
  refreshMarginBaseRegisters: number = -1;
  refreshMarginBases: number = 0;

  constructor() {
    super();
    this.showManageModal = false;
    this.showDeleteModal = false;
    this.showMarginBaseRegistersModal = false;
    this.item = { ...this.defaultItem };
    this.marginBases = { ...this.defaultReturn };
    this.marginBaseRegisters = { ...this.defaultReturn };
    this.service = MarginBaseService.getInstance();
  }

  async deleteMarginBase(): Promise<void> {
    const [err, marginBaseData] = await this.service.deleteMarginBase({
      marginBaseId: this.item.id!
    });

    if (!err && marginBaseData) {
      this.$notify({
        title: "Base de margem",
        type: "success",
        text: "Arquivo excluído com sucesso."
      });
      this.handleRefreshMarginBases();
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Base de margem", type: "error", text: message });
    }

    this.closeDeleteMarginBaseModal();
  }

  async fetchMarginBases(options: ListMarginBaseParams): Promise<void> {
    const { page, limit, search, sort } = options;
    const [err, marginBaseData] = await this.service.listMarginBases({
      companyId: this.companyId,
      page,
      limit,
      search,
      sort
    });

    if (!err) {
      this.marginBases = marginBaseData;
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Base de margem", type: "error", text: message });
    }
  }

  async fetchMarginBaseRegisters(
    options: ListMarginBaseRegistersParams
  ): Promise<void> {
    const { page, limit, search, sort, marginBaseId } = options;
    const [err, marginBaseRegisters] =
      await this.service.listMarginBaseRegisters({
        marginBaseId,
        page,
        limit,
        search,
        sort
      });

    if (!err) {
      this.marginBaseRegisters = marginBaseRegisters;
    } else {
      const message = getErrorMessageFromApiError(err);
      this.$notify({ title: "Base de margem", type: "error", text: message });
    }
  }

  handleAction(action: ManageEvent): void {
    this.item = { ...action.item };

    if ("manage" in action) {
      this.showManageModal = action.manage || false;
    } else if ("delete" in action) {
      this.showDeleteModal = action.delete || false;
    } else if ("registers" in action) {
      this.showMarginBaseRegistersModal = action.registers || false;
      this.marginBaseRegisters = { ...this.defaultReturn };
      this.handleRefreshMarginBaseRegisters();
    }
  }

  get companyId() {
    return store.getters["auth/currentUserCompanyId"];
  }

  @Watch("companyId")
  handleRefreshMarginBases() {
    this.refreshMarginBases++;
  }

  handleRefreshMarginBaseRegisters() {
    this.refreshMarginBaseRegisters++;
  }

  closeMarginBaseRegistersModal(): void {
    this.showMarginBaseRegistersModal = false;
    // this.marginBaseRegisters = { items: [], total: 0 };
    this.item = { ...this.defaultItem };
  }

  closeMarginBaseManageModal(): void {
    this.showManageModal = false;
    this.item = { ...this.defaultItem };
  }

  closeDeleteMarginBaseModal(): void {
    this.showDeleteModal = false;
    this.item = { ...this.defaultItem };
  }
}
