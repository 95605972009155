import dayjs from "dayjs";
import { DebtAgreement } from "../types/debt-agreement";
import HttpClient, { Response } from "./http-client";
import { DebtAgreementLoanInstallment } from "@/types/debt-agreement-loan-installment";
import { DebtAgreementInstallmentsStatus } from "@/types/debt-agreement-installments-status";
import { DebtAgreementInstallment } from "@/types/debt-agreement-installment";

export default class DebtAgreementInstallmentsService extends HttpClient {
  private static classInstance?: DebtAgreementInstallmentsService;

  private constructor() {
    super(process.env.VUE_APP_DEBT_AGREEMENT_URL!);
  }

  public static getInstance(): DebtAgreementInstallmentsService {
    if (!this.classInstance) {
      this.classInstance = new DebtAgreementInstallmentsService();
    }
    return this.classInstance;
  }

  public async findDebtAgreementById(
    id: number
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "GET",
      url: `/debt-agreement/${id}`
    });
  }

  public async listDebtAgreementsInstallments(
    filters: InstallmentsDebtAgreementFilters
  ): Promise<Response<ListInstallmentsDebtAgreementsResponseDto>> {
    return this.axiosCall<ListInstallmentsDebtAgreementsResponseDto>({
      method: "GET",
      url: "/debt-agreement/debt-agreements-installments/list",
      params: {
        ...filters,
        createdAtStart: filters.createdAtStart
          ? dayjs(filters.createdAtStart).format("YYYY-MM-DD")
          : undefined,
        createdAtEnd: filters.createdAtEnd
          ? dayjs(filters.createdAtEnd).format("YYYY-MM-DD")
          : undefined,
        dueDateStart: filters.dueDateStart
          ? dayjs(filters.dueDateStart).format("YYYY-MM-DD")
          : undefined,
        dueDateEnd: filters.dueDateEnd
          ? dayjs(filters.dueDateEnd).format("YYYY-MM-DD")
          : undefined
      }
    });
  }

  public async listDebtAgreementInstallmentsStatusList(): Promise<
    Response<DebtAgreementInstallmentsStatus[]>
  > {
    return this.axiosCall<DebtAgreementInstallmentsStatus[]>({
      method: "GET",
      url: "/debt-agreement/debt-agreements-installments/status"
    });
  }

  public async generateDebtAgreementsReportXls(
    params: GenerateDebtAgreementsInstallmentsReportXlsParams
  ): Promise<Response<GenerateDebtAgreementsInstallmentsReportXlsResponse>> {
    return this.axiosCall<GenerateDebtAgreementsInstallmentsReportXlsResponse>({
      method: "POST",
      url: `/reports/debt-agreement-installments/generate-xls-token`,
      params
    });
  }

  public async generateBankSlipForInstallment(
    params: GenerateBankSlipForDebtAgreementInstallmentDto
  ): Promise<Response<boolean>> {
    return this.axiosCall<boolean>({
      method: "PATCH",
      url: "/debt-agreement/debt-agreements-installments/generateBankSlip",
      params,
      data: params
    });
  }
}

export interface InstallmentsDebtAgreementFilters {
  debtAgreementId?: number;
  borrowerId?: number;
  createdAtStart?: Date;
  createdAtEnd?: Date;
  dueDateStart?: Date;
  dueDateEnd?: Date;
  proposalNumber?: number;
  statusId?: number;
  installmentNumber?: number;
  loadRelations?: boolean;
  sort?: string;
  page?: number;
  limit?: number;
}

export interface ListInstallmentsDebtAgreementsResponseDto {
  data: DebtAgreementInstallment[];
  total: number;
}

export type GenerateDebtAgreementsInstallmentsReportXlsParams =
  InstallmentsDebtAgreementFilters;

export interface GenerateDebtAgreementsInstallmentsReportXlsResponse {
  token: string;
}

export interface GenerateBankSlipForDebtAgreementInstallmentDto {
  id: number;
}
