import Vue from "vue";
import { hasPermissions } from "@/utils/authorization";

Vue.directive("permissions", {
  bind(el, binding, vnode) {
    const requiredPermissions = binding.value;
    if (!requiredPermissions.length) {
      return;
    }
    const userPermissions =
      vnode && vnode.context ? vnode.context.$store.getters["auth/userPermissions"] : [];
    hasPermissions(requiredPermissions, userPermissions);

    if (!hasPermissions(requiredPermissions, userPermissions)) {
      //el.innerText = 'Você não tem permissão para visualizar esta página.';
      //router.push({ name: 'PageNotAuthorized' });
      el.style.display = "none";
    }
  },
});
