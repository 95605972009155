
import { Component, Vue, Prop, Watch } from "vue-property-decorator";
import { ValidationProvider } from "vee-validate";

@Component({
  components: { ValidationProvider }
})
export default class VSelectWithValidation extends Vue {
  innerValue: string | number;

  constructor() {
    super();
    this.innerValue = "";
  }

  @Prop([Object, String]) readonly rules?: any;
  @Prop() readonly value?: string | number | undefined;
  @Prop(String) readonly vid!: string | undefined;
  @Prop(Number) readonly index?: number | undefined;

  @Watch("innerValue")
  onInnerValueChanged(val: string | number): void {
    this.$emit("input", val);
    this.$emit("changed", val)
  }

  @Watch("value")
  onValueChanged(val: string | number): void {
    this.innerValue = val;
  }

  created(): void {
    if (this.value) {
      this.innerValue = this.value;
    }
  }
}
