
import DatePicker from "@/components/DatePicker.vue";
import Modal from "@/components/Modal.vue";
import LoanService from "@/services/loan-service";
import store from "@/store";
import Payment from "@/types/payment";
import PaymentStatus from "@/types/payment-status";
import { PaymentTypeEnum } from "@/types/payment-type";
import { formatDateISO8601 } from "@/utils/formatDateISO8601";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import dayjs from "dayjs";
import { ValidationObserver, ValidationProvider } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { mask } from "vue-the-mask";
import { getBankSlipCurrentStatus } from "@/utils/bankslip";
import PaymentService from "@/services/payment-service";
import { PaymentStatusEnum } from "@/types/payment-status";
import { PaymentOriginEnum } from "@/types/payment-origin";
import { DebtAgreementInstallment } from "@/types/debt-agreement-installment";
import LoanInstallment from "@/types/loan-installment";

@Component({
  components: { ValidationObserver, ValidationProvider, Modal, DatePicker },
  directives: { mask }
})
export default class ChargeManageModal extends Vue {
  userType: string;
  loading = false;
  loadingInstallments = false;
  fileManagerUrl = process.env.VUE_APP_FILE_MANAGER_URL;
  PaymentTypeEnum = PaymentTypeEnum;
  loanService: LoanService;
  paymentService: PaymentService;
  paymentType: string;
  paymentLabel: string;
  paymentOrigin: string;
  totalContracts: number;
  totalInstallments: number;
  PaymentStatusEnum = PaymentStatusEnum;
  possiblePaymentStatus: PaymentStatus[] = [];
  isUnlinkedFromRefin: boolean;
  PaymentOriginEnum = PaymentOriginEnum;
  paymentInstallments = [];

  showInstallmentsDetails = [
    this.PaymentOriginEnum.BORROWER.id,
    this.PaymentOriginEnum.DEBIT_AGREEMENT.id,
    this.PaymentOriginEnum.RESCISSION.id,
    this.PaymentOriginEnum.SETTLE_CONTRACT.id
  ];

  showChargeRelations = [
    this.PaymentOriginEnum.BORROWER.id,
    this.PaymentOriginEnum.DEBIT_AGREEMENT.id,
    this.PaymentOriginEnum.RESCISSION.id,
    this.PaymentOriginEnum.SETTLE_CONTRACT.id,
    this.PaymentOriginEnum.SEPARATE_CHARGE.id,
    this.PaymentOriginEnum.BATCH.id
  ];

  headers = [
    { text: "Contrato", value: "contract" },
    { text: "Parcela", value: "installmentNumber" },
    { text: "Valor Parcela", value: "value" },
    { text: "Data Vencimento", value: "dueDate" }
  ];

  installmentsTableData = [];

  @Prop() readonly payment?: Payment | undefined;
  @Prop() readonly paymentStatus?: PaymentStatus[] | undefined;

  form: Partial<Payment>;
  getBankSlipCurrentStatus = getBankSlipCurrentStatus;

  constructor() {
    super();
    this.loanService = LoanService.getInstance();
    this.paymentService = PaymentService.getInstance();
    const authenticatedUser = store.getters["auth/authenticatedUser"];
    this.userType = authenticatedUser.type;
    this.possiblePaymentStatus = this.paymentStatus.filter(
      (status) => status.name !== PaymentStatusEnum.UNLINKED_FROM_REFIN.name
    );
    this.isUnlinkedFromRefin =
      this.payment?.status.name === PaymentStatusEnum.UNLINKED_FROM_REFIN.name;
    this.form = {
      paidValue: this.payment?.paidValue,
      paidDate: this.payment?.paidDate,
      statusName: this.payment?.status.name
    };

    if (
      this.payment &&
      this.payment?.paymentOthers &&
      this.payment?.type?.id &&
      this.payment?.type?.id == PaymentTypeEnum.OUTROS
    ) {
      this.paymentType = "paymentOthers";
      this.paymentLabel = "Situação pagamento";
    }
    if (
      this.payment &&
      this.payment?.paymentBankSlip &&
      this.payment?.type?.id &&
      this.payment?.type?.id == PaymentTypeEnum.BOLETO_BANCARIO
    ) {
      this.paymentType = "paymentBankSlip";
      this.paymentLabel = "Situação boleto";
    }
    this.payment?.origin?.id == PaymentOriginEnum.BATCH.id
      ? (this.paymentOrigin = `${PaymentOriginEnum.BATCH.description} ${this.payment.paymentBatch.batchId}`)
      : (this.paymentOrigin = this.payment.origin.description);
  }

  async created() {
    this.loading = true;

    if (this.payment && this.shouldShowIncludedInstallments()) {
      this.loadingInstallments = true;
      const [error, installments] =
        await this.paymentService.listInstallmentsByPaymentId(this.payment.id);

      if (!installments || installments.length === 0) {
        this.loadingInstallments = false;
        this.loading = false;
        return;
      }

      this.totalInstallments = installments.length;
      this.totalContracts = 1;

      if (this.payment.origin.id === PaymentOriginEnum.DEBIT_AGREEMENT.id) {
        const debtAgreementInstallments =
          installments as DebtAgreementInstallment[];

        this.installmentsTableData = debtAgreementInstallments.map(
          (installment) => ({
            contract: installment.debtAgreement.loanProposalNumber,
            installmentNumber: installment.number,
            value: installment.value,
            dueDate: installment.dueDate
          })
        );
        this.loadingInstallments = false;
        this.loading = false;
        return;
      }

      const loanInstallments = installments as LoanInstallment[];
      this.installmentsTableData = loanInstallments.map((installment) => ({
        contract: installment.loan?.proposalNumber,
        installmentNumber: installment.number,
        value: installment.value,
        dueDate: installment.dueDate
      }));

      this.loadingInstallments = false;
    }

    if (this.payment.origin.id === PaymentOriginEnum.BATCH.id) {
      const [error, batchRegisters] = await this.loanService.listBatchRegisters(
        {
          batchId: this.payment.paymentBatch.batchId
        }
      );

      if (error) {
        this.$notify({
          type: "error",
          text: getErrorMessageFromApiError(error)
        });
        this.loading = false;
        return;
      }

      const paidRegisters = batchRegisters.filter(
        (register) =>
          (register.reconciliate === true &&
            Number(register.reconciliationValue) > 0) ||
          (register.reconciliate === false &&
            Number(register.severancePayValue) > 0)
      );

      this.totalInstallments = paidRegisters.length;

      const uniqueLoanIds = new Set(
        paidRegisters.map((register) => register.loanInstallment.loan.id)
      );

      this.totalContracts = uniqueLoanIds.size;
    }

    this.loading = false;
    return;
  }

  async save() {
    this.loading = true;
    let error, payment;
    [error, payment] = await this.paymentService.updatePayment({
      paymentId: this.payment!.id,
      status: this.form.statusName!,
      paidDate: formatDateISO8601(this.form.paidDate!),
      paidValue: this.form.paidValue
    });

    if (!error) {
      this.$notify({
        type: "success",
        text: "Pagamento atualizado com sucesso"
      });
      this.$emit("input", payment);
      this.close();
    } else {
      this.$notify({
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    }
    this.loading = false;
  }

  formatDate(date?: Date | null | undefined): string {
    return date ? dayjs(date).format("DD/MM/YYYY") : "-";
  }

  close() {
    this.$emit("close");
  }

  shouldShowIncludedInstallments() {
    if (this.showInstallmentsDetails.includes(this.payment.origin.id))
      return true;

    return false;
  }

  shouldShowChargeRelations() {
    if (this.showChargeRelations.includes(this.payment.origin.id)) return true;

    return false;
  }
}
