export interface DebtAgreementStatus {
  id: number;
  name: string;
  description: string;
}

const registeredStatus: DebtAgreementStatus = {} as DebtAgreementStatus;
registeredStatus.id = 1;
registeredStatus.name = "CADASTRADO";
registeredStatus.description = "Cadastrado";

const effectivatedStatus: DebtAgreementStatus = {} as DebtAgreementStatus;
effectivatedStatus.id = 2;
effectivatedStatus.name = "EFETIVADO";
effectivatedStatus.description = "Efetivado";

const canceledStatus: DebtAgreementStatus = {} as DebtAgreementStatus;
canceledStatus.id = 3;
canceledStatus.name = "CANCELADO";
canceledStatus.description = "Cancelado";

const paidOffStatus: DebtAgreementStatus = {} as DebtAgreementStatus;
paidOffStatus.id = 4;
paidOffStatus.name = "LIQUIDADO";
paidOffStatus.description = "Liquidado";

export const DebtAgreementStatusEnum = {
  REGISTERED: registeredStatus,
  EFFECTIVATED: effectivatedStatus,
  CANCELED: canceledStatus,
  PAID_OFF: paidOffStatus
};
