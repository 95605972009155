import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";

dayjs.extend(isSameOrAfter);

export default function isValidDebtPurchaseDueDate(
  value: dayjs.Dayjs
): boolean {
  const MIN_DAYS_TO_DUE_DATE = 0;

  const minimumDueDate = dayjs()
    .add(MIN_DAYS_TO_DUE_DATE, "days")
    .startOf("day");

  const debtPurchaseDueDate = dayjs(value).startOf("day");

  return debtPurchaseDueDate.isSameOrAfter(minimumDueDate);
}
