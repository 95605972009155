
import { mask } from "vue-the-mask";
import { Component, Vue } from "vue-property-decorator";

@Component({
  directives: { mask }
})
export default class DebtAgreementEditModalCpfInput extends Vue {
  borrowerCpf: string = "";

  onInputCpf(): void {
    this.$emit("input", this.borrowerCpf);
  }

  onFinishCpfInput(): void {
    this.$emit("next");
  }
}
