
import { Vue, Component, Prop } from "vue-property-decorator";
import MarginBaseService from "@/services/margin-base-service";
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore
import vueFilePond, { setOptions } from "vue-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import store from "@/store";

const FilePond = vueFilePond(
  FilePondPluginFileValidateType,
  FilePondPluginFileValidateSize
);
import pt_BR from "../locales/filepond/pt_BR";

@Component({ components: { FilePond } })
export default class FileUpload extends Vue {
  @Prop(Boolean) readonly allowMultiple!: boolean;
  @Prop(String) readonly acceptedTypes!: string;
  @Prop(String) readonly maxSize!: string;
  @Prop(String) readonly minSize!: string;
  @Prop(String) readonly labelIdle!: string;
  @Prop(String) readonly entityName!: string;
  @Prop(String) readonly acceptedTypesAlias!: any;
  @Prop(String) readonly uploadUrl!: string;

  file: any;
  service: MarginBaseService;
  isUploading: boolean = false;

  constructor() {
    super();
    this.service = MarginBaseService.getInstance();
  }

  handleFilesUpdated(file: any) {
    this.file = file[0];
    return this.$emit("filesChanged", this.file);
  }

  handleRemoveLocalFiles() {
    return this.$refs.pond.removeFiles();
  }

  handleStartUpload() {
    return this.$emit("isUploading", true);
  }

  handleEndedUpload() {
    return this.$emit("isUploading", false);
  }

  handleFinishedUpload(): void {
    this.handleEndedUpload();
    this.$emit("isUploaded", true);
    // this.handleRemoveLocalFiles();
    return this.$notify({
      title: this.entityName,
      duration: 4000,
      type: "success",
      text: "Importação realizada com sucesso.",
    });
  }

  $refs!: {
    pond: any;
  };

  mounted() {
    const token = this.$store.getters["auth/token"];
    setOptions({
      ...pt_BR,
      server: {
        url: this.uploadUrl,
        headers: {
          iorigin: "AP",
          Authorization: `Bearer ${token}`,
        },
        // chunkUploads: true,
        // chunkSize: 100000,
        process: {
          ondata: (formData: FormData) => {
            const companyId = store.getters["auth/currentUserCompanyId"];
            formData.append("companyId", companyId);
            return formData;
          },
          onerror: (response: any) => {
            this.handleEndedUpload();
            const message =
              JSON.parse(response).message ||
              "Algo deu errado durante a importação.";
            return this.$notify({
              title: this.entityName,
              duration: 6000,
              type: "error",
              text: message,
            });
          },
        },
      },
    });
  }
}
