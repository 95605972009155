
import { mask } from "vue-the-mask";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DebtAgreement } from "../../types/debt-agreement";
import DebtAgreementDetail from "./DebtAgreementDetail.vue";
import DebtAgreementService from "../../services/debt-agreement-service";
import getErrorMessageFromApiError from "../../utils/getErrorMessageFromApiError";

@Component({
  directives: { mask },
  components: {
    DebtAgreementDetail
  }
})
export default class DebtAgreementEditModalProposal extends Vue {
  @Prop() debtAgreement!: DebtAgreement;

  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();

  loading: boolean = false;

  async effectivate(): Promise<void> {
    this.loading = true;

    const [error, response] =
      await this.debtAgreementService.effectivateDebtAgreement(
        this.debtAgreement.id
      );

    if (error) {
      this.$notify({
        title: "Acordo",
        type: "error",
        text: getErrorMessageFromApiError(error)
      });
    } else {
      this.$notify({
        title: "Acordo",
        type: "success",
        text: "Acordo efetivado com sucesso"
      });

      this.$emit("next", response);
    }

    this.loading = false;
  }

  back(): void {
    this.$emit("back");
  }
}
