
import { Vue, Component, Prop, Watch } from "vue-property-decorator";
import debounce from "debounce";

@Component
export default class VInfiniteScroll extends Vue {
  @Prop({ type: Function, required: true }) fetchFunction!: (params: {
    page: number;
    limit: number;
    search?: string;
  }) => Promise<{ items: Array<any>; total: number }>;
  /** Filters fetched results. Used for removing values that cannot be selected by the user from the list */
  @Prop({ type: Function, required: false }) filterFunction!: (
    partner: any
  ) => boolean;
  @Prop({ type: Object, default: null }) initialSelectedItem!: any;
  @Prop({ type: String, required: true }) itemValue!: string;
  @Prop({ type: [String, Function], required: true }) itemText!:
    | string
    | ((item: any) => string);
  @Prop({ type: String, default: "" }) label!: string;
  @Prop({ type: Number, default: 100 }) limit!: number;
  @Prop({ type: Object, default: () => ({}) }) autocompleteProps!: Record<
    string,
    any
  >;

  items: Array<any> = [];
  total: number = 0;
  page: number = 1;
  searchInput: string | null = null;
  loading: boolean = false;
  selectedItem: any = null;

  @Watch("initialSelectedItem")
  onInitialSelectedItemProvided(newVal: any, oldVal: any): void {
    if (newVal) {
      this.addItemToStartOfList(this.initialSelectedItem);
      this.selectedItem = this.initialSelectedItem;
    }
  }

  @Watch("selectedItem")
  onSelectedItemChange(newVal: any, oldVal: any): void {
    this.$emit("item-selected", newVal ?? null);
  }

  mounted(): void {
    this.loadItems({ clearExisting: false });
  }

  addItemToStartOfList(item: any): void {
    this.items = this.items.filter(
      (existingItem) => existingItem[this.itemValue] !== item[this.itemValue]
    );

    this.items.unshift(item);
  }

  async loadItems({
    clearExisting
  }: {
    clearExisting?: boolean;
  }): Promise<void> {
    if (clearExisting) {
      this.page = 1;
    }

    const thereAreMoreItemsToFetch =
      this.page === 1 || this.total > this.items.length;
    if (thereAreMoreItemsToFetch) {
      this.loading = true;

      try {
        const { items: newItems, total } = await this.fetchFunction({
          page: this.page,
          limit: this.limit,
          search:
            this.searchInput !== this.selectedItem?.name
              ? this.searchInput
              : undefined
        });

        let items = clearExisting ? newItems : [...this.items, ...newItems];
        if (this.filterFunction) items = items.filter(this.filterFunction);

        this.items = items;
        this.total = total;
        this.page += 1;

        const itemToPrepend = this.selectedItem || this.initialSelectedItem;
        if (itemToPrepend) {
          this.addItemToStartOfList(itemToPrepend);
        }
      } catch (error) {
        this.$emit("fetch-error", error);
      } finally {
        this.loading = false;
      }
    }
  }

  get debouncedLoadItems() {
    return debounce(this.loadItems, 1000);
  }

  async onSearchInput(): Promise<void> {
    this.debouncedLoadItems({ clearExisting: true });
  }

  async onIntersect(
    _entries: any,
    _observer: any,
    isIntersecting: boolean
  ): Promise<void> {
    if (isIntersecting) {
      await this.loadItems({});
    }
  }
}
