import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./plugins/axios";
import "./plugins/vee-validate";
import "./plugins/base-components";
import "./plugins/vue-notification";
import "./plugins/vue2-filters";
import "./plugins/vuetify-money";
import "./plugins/vue-the-mask";
import "./utils/directives/permissions";

import i18n from "@/plugins/i18n";
import { hasPermissions } from "./utils/authorization";

Vue.config.productionTip = false;

const globalMixins = {
  methods: {
    hasPermissions(permissions: string[]) {
      const userPermissions = store.getters["auth/userPermissions"];
      return hasPermissions(permissions, userPermissions);
    }
  }
};

Vue.mixin(globalMixins);

new Vue({
  router,
  store,
  vuetify,
  i18n,
  render: (h) => h(App)
}).$mount("#app");
