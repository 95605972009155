/** Checks if user has all required permissions */
export function hasPermissions(
  requiredPermissions: string[],
  userPermissions: string[]
): boolean {
  if (!requiredPermissions?.length) {
    return true;
  }

  if (
    userPermissions &&
    userPermissions.length &&
    requiredPermissions.every((requiredPermission) => {
      return !!userPermissions.find((userPermission) => {
        const matchesExactly = userPermission === requiredPermission;
        const matchesWildcard = userPermission.startsWith(
          `${requiredPermission}.`
        );
        return matchesExactly || matchesWildcard;
      });
    })
  ) {
    return true;
  }

  return false;
}
