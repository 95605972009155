<template>
  <v-alert class="alert-box mb-6" v-bind="attrs">
    {{ message }}
  </v-alert>
</template>

<script>
export default {
  props: {
    attrs: {
      type: Object,
      default: () => {
        return {
          class: "text-left",
          rounded: "xl"
        };
      }
    },
    message: {
      type: String,
      default: ""
    }
  }
};
</script>
<style lang="scss">
.v-alert.alert-box {
  background-color: $alertColor;
  color: $darkBlue;
  padding: 28px 32px;
  font-weight: 900;
  width: 100%;
  border-radius: 15px !important;
}
</style>
<style scoped lang="scss">
@import "../scss/styles-from-borrower-portal.scss";
</style>
