import dayjs from "dayjs";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";

dayjs.extend(isSameOrBefore);

export function currentOrPastDate(date: string, extraDays = 0): boolean {
  return dayjs(date, "DD/MM/YYYY", true).isSameOrBefore(
    dayjs().add(extraDays, "days"),
    "date"
  );
}
