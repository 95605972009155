
import Modal from "@/components/Modal.vue";
import { Component, Prop, Vue } from "vue-property-decorator";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import DebtAgreementService from "../../services/debt-agreement-service";
import { DebtAgreement } from "../../types/debt-agreement";
import formatCurrency from "../../utils/formatCurrency";
import formatDatetime from "../../utils/formatDatetime";

@Component({
  components: {
    Modal
  }
})
export default class DebtAgreementDetail extends Vue {
  @Prop() item!: DebtAgreement;
  detailedItem: DebtAgreement = { ...this.item };

  formatCPF = formatCPF;
  formatDate = formatDate;
  formatDatetime = formatDatetime;
  formatCurrency = formatCurrency;

  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();

  loadingRelations: boolean = true;

  async mounted(): Promise<void> {
    const [error, debtAgreement] =
      await this.debtAgreementService.findDebtAgreementById(this.item.id);

    if (!error) {
      this.detailedItem = debtAgreement!;
    } else {
      this.$notify({
        title: "Acordo",
        type: "error",
        text: "Erro ao carregar os detalhes do acordo"
      });
    }

    this.loadingRelations = false;
  }

  close(): void {
    this.$emit("close");
  }
}
