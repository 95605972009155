export default interface PaymentOrigin {
  id: number;
  name: string;
  description: string;
}

const batch: PaymentOrigin = {
  id: 1,
  name: "LOTE",
  description: "Lote"
};

const batchManual: PaymentOrigin = {
  id: 2,
  name: "LOTE_MANUAL",
  description: "Lote manual"
};

const separateCharge: PaymentOrigin = {
  id: 3,
  name: "AVULSO",
  description: "Avulso"
};

const rescission: PaymentOrigin = {
  id: 4,
  name: "RESCISAO",
  description: "Rescisão"
};

const manual: PaymentOrigin = {
  id: 5,
  name: "MANUAL",
  description: "Manual"
};

const refinancing: PaymentOrigin = {
  id: 6,
  name: "REFINANCIAMENTO",
  description: "Refinanciamento"
};

const borrowerOrigin: PaymentOrigin = {
  id: 7,
  name: "TOMADOR",
  description: "Tomador"
};

const settleContractOrigin: PaymentOrigin = {
  id: 8,
  name: "LIQUIDAR_CONTRATO  ",
  description: "Liquidar Contrato"
};

const debtAgreementOrigin: PaymentOrigin = {
  id: 9,
  name: "TOMADOR_ACORDO",
  description: "Tomador Acordo"
};

export const PaymentOriginEnum = {
  BATCH: batch,
  BATCH_MANUAL: batchManual,
  SEPARATE_CHARGE: separateCharge,
  RESCISSION: rescission,
  MANUAL: manual,
  REFINANCING: refinancing,
  BORROWER: borrowerOrigin,
  SETTLE_CONTRACT: settleContractOrigin,
  DEBIT_AGREEMENT: debtAgreementOrigin
};

export const PaymentOriginMap = {
  [PaymentOriginEnum.BATCH.id]: PaymentOriginEnum.BATCH.description,
  [PaymentOriginEnum.REFINANCING.id]: PaymentOriginEnum.REFINANCING.description,
  [PaymentOriginEnum.BATCH_MANUAL.id]: PaymentOriginEnum.BATCH_MANUAL.description,
  [PaymentOriginEnum.SEPARATE_CHARGE.id]: PaymentOriginEnum.SEPARATE_CHARGE.description,
  [PaymentOriginEnum.RESCISSION.id]: PaymentOriginEnum.RESCISSION.description,
  [PaymentOriginEnum.MANUAL.id]: PaymentOriginEnum.MANUAL.description,
  [PaymentOriginEnum.BORROWER.id]: PaymentOriginEnum.BORROWER.description,
  [PaymentOriginEnum.SETTLE_CONTRACT.id]: PaymentOriginEnum.SETTLE_CONTRACT.description,
  [PaymentOriginEnum.DEBIT_AGREEMENT.id]: PaymentOriginEnum.DEBIT_AGREEMENT.description
};
