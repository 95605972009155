export default interface PayerType {
  id: number;
  name: string;
  description?: string;
  created_at: Date;
  updated_at: Date;
  deleted_at: Date | null;
}

export enum PayerTypeEnum {
  EMPRESA = 1,
  TOMADOR = 2,
  LIQUIDAR_CONTRATO = 3
}
