
import Modal from "@/components/Modal.vue";
import { ValidationObserver } from "vee-validate";
import { Component, Prop, Vue } from "vue-property-decorator";
import { DebtAgreement } from "../../types/debt-agreement";
import DebtAgreementDetail from "./DebtAgreementDetail.vue";

@Component({
  components: {
    ValidationObserver,
    Modal,
    DebtAgreementDetail
  }
})
export default class DebtAgreementDetailModal extends Vue {
  @Prop() item!: DebtAgreement;

  close(): void {
    this.$emit("close");
  }
}
