const clt = {
  id: 1,
  name: "CLT"
};

const pj = {
  id: 2,
  name: "PJ"
};

/*
 * Enum
 */

export const MarginBaseRegisterTypeEnum = {
  CLT: clt,
  PJ: pj
};
