import dayjs from "dayjs";
import { DebtAgreement } from "../types/debt-agreement";
import HttpClient, { Response } from "./http-client";
import { DebtAgreementStatus } from "../types/debt-agreement-status";
import Loan from "../types/loan";
import LoanInstallment from "../types/loan-installment";

export default class DebtAgreementService extends HttpClient {
  private static classInstance?: DebtAgreementService;

  private constructor() {
    super(process.env.VUE_APP_DEBT_AGREEMENT_URL!);
  }

  public static getInstance(): DebtAgreementService {
    if (!this.classInstance) {
      this.classInstance = new DebtAgreementService();
    }
    return this.classInstance;
  }

  public async findDebtAgreementById(
    id: number
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "GET",
      url: `/debt-agreement/${id}`
    });
  }

  public async listDebtAgreements(
    filters: ListDebtAgreementsParamsDto
  ): Promise<Response<ListDebtAgreementsResponseDto>> {
    return this.axiosCall<ListDebtAgreementsResponseDto>({
      method: "GET",
      url: "/debt-agreement",
      params: {
        ...filters,
        createdAtStart: filters.createdAtStart
          ? dayjs(filters.createdAtStart).format("YYYY-MM-DD")
          : undefined,
        createdAtEnd: filters.createdAtEnd
          ? dayjs(filters.createdAtEnd).format("YYYY-MM-DD")
          : undefined,
        effectiveDateStart: filters.effectiveDateStart
          ? dayjs(filters.effectiveDateStart).format("YYYY-MM-DD")
          : undefined,
        effectiveDateEnd: filters.effectiveDateEnd
          ? dayjs(filters.effectiveDateEnd).format("YYYY-MM-DD")
          : undefined,
        firstInstallmentDueDateStart: filters.firstInstallmentDueDateStart
          ? dayjs(filters.firstInstallmentDueDateStart).format("YYYY-MM-DD")
          : undefined,
        firstInstallmentDueDateEnd: filters.firstInstallmentDueDateEnd
          ? dayjs(filters.firstInstallmentDueDateEnd).format("YYYY-MM-DD")
          : undefined
      }
    });
  }

  public async listDebtAgreementStatusList(): Promise<
    Response<DebtAgreementStatus[]>
  > {
    return this.axiosCall<DebtAgreementStatus[]>({
      method: "GET",
      url: "/debt-agreement/status"
    });
  }

  public async consultEligibleLoansForCpf(
    borrowerCpf: string
  ): Promise<Response<LoanWithDaysLate[]>> {
    return this.axiosCall<LoanWithDaysLate[]>({
      method: "GET",
      url: "/debt-agreement/consult",
      params: {
        borrowerCpf
      }
    });
  }

  public async createDebtAgreement(
    data: CreateDebtAgreementDto
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "POST",
      url: "/debt-agreement",
      data
    });
  }

  public async updateDebtAgreement(
    id: number,
    data: UpdateDebtAgreementDto
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "PATCH",
      url: `/debt-agreement/${id}`,
      data
    });
  }

  public async effectivateDebtAgreement(
    id: number
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "PATCH",
      url: `/debt-agreement/${id}/effectivate`,
      data: { id } // to avoid Akamai issue with empty body in PATCH requests
    });
  }

  public async cancelDebtAgreement(
    id: number
  ): Promise<Response<DebtAgreement>> {
    return this.axiosCall<DebtAgreement>({
      method: "PATCH",
      url: `/debt-agreement/${id}/cancel`,
      data: { id } // to avoid Akamai issue with empty body in PATCH requests
    });
  }

  public async generateDebtAgreementsReportXls(
    params: GenerateDebtAgreementsReportXlsParams
  ): Promise<Response<GenerateDebtAgreementsReportXlsResponse>> {
    return this.axiosCall<GenerateDebtAgreementsReportXlsResponse>({
      method: "POST",
      url: `/reports/debt-agreement/generate-xls-token`,
      params
    });
  }
}

export interface ListDebtAgreementsParamsDto {
  limit: number;
  page: number;
  id?: number;
  statusId?: number;
  responsibleUserId?: number;
  loanProposalNumber?: string;
  borrowerId?: number;
  borrowerCpf?: string;
  borrowerName?: string;
  createdAtStart?: Date;
  createdAtEnd?: Date;
  effectiveDateStart?: Date;
  effectiveDateEnd?: Date;
  firstInstallmentDueDateStart?: Date;
  firstInstallmentDueDateEnd?: Date;
  loadRelations?: boolean;
}

export interface ListDebtAgreementsResponseDto {
  data: DebtAgreement[];
  total: number;
}

export type LoanInstallmentWithDaysLate = LoanInstallment & {
  daysLate: number;
};

export type LoanWithDaysLate = Omit<Loan, "installments"> & {
  daysLate: number;
  debitValue?: number;
  installments: LoanInstallmentWithDaysLate[];
};

export interface CreateDebtAgreementDto {
  loanId: number;
  loanInstallmentIds: number[];
  negotiatedValue: number;
  downPaymentValue?: number;
  numInstallments: number;
  firstInstallmentDueDate: string;
  updatePresentValue?: boolean;
}

export type GenerateDebtAgreementsReportXlsParams = ListDebtAgreementsParamsDto;

export interface GenerateDebtAgreementsReportXlsResponse {
  token: string;
}

export type UpdateDebtAgreementDto = Partial<CreateDebtAgreementDto>;
