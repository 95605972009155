import { removeNonDigits } from "./removeNonDigits";

export function validate(rawCpf: string) {
  const cpf = removeNonDigits(rawCpf);

  let sum, mod, i;
  sum = 0;
  if (cpf == "00000000000") return false;
  for (i = 1; i <= 9; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (11 - i);
  mod = (sum * 10) % 11;
  if (mod == 10 || mod == 11) mod = 0;
  if (mod != parseInt(cpf.substring(9, 10))) return false;
  sum = 0;
  for (i = 1; i <= 10; i++)
    sum = sum + parseInt(cpf.substring(i - 1, i)) * (12 - i);
  mod = (sum * 10) % 11;
  if (mod == 10 || mod == 11) mod = 0;
  if (mod != parseInt(cpf.substring(10, 11))) return false;
  return true;
}

export function format(text: string): string {
  const mask = /(\d{3})(\d{3})(\d{3})(\d{2})/;
  const cpf = new String(text).replace(/[^\d]/g, "");
  return cpf.replace(mask, "$1.$2.$3-$4");
}
