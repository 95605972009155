var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('Modal',{attrs:{"show":true,"title":_vm.debtAgreementToContinue
      ? `Continuar acordo nº ${_vm.debtAgreementToContinue.id}`
      : 'Novo acordo',"fullscreen":""},on:{"close":function($event){return _vm.close()}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.step === _vm.Step.CPF)?_c('div',[_c('DebtAgreementEditModalCpfInput',{on:{"input":_vm.onInputCpf,"next":() => (_vm.step = _vm.Step.CONDITIONS)}})],1):(_vm.step === _vm.Step.CONDITIONS)?_c('div',[_c('DebtAgreementEditModalConditions',{attrs:{"debtAgreement":_vm.debtAgreement,"borrowerCpf":_vm.borrowerCpf},on:{"next":(updatedDebtAgreement) => {
            _vm.debtAgreement = updatedDebtAgreement;
            _vm.step = _vm.Step.PROPOSAL;
          },"close":_vm.close}})],1):(_vm.step === _vm.Step.PROPOSAL)?_c('div',[_c('DebtAgreementEditModalProposal',{attrs:{"debtAgreement":_vm.debtAgreement},on:{"back":() => (_vm.step = _vm.Step.CONDITIONS),"next":(updatedDebtAgreement) => {
            _vm.debtAgreement = updatedDebtAgreement;
            _vm.close();
            _vm.refresh();
          },"close":_vm.close}})],1):_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }