import State from "@/types/auth-state";

export default {
  setAuthenticationError(state: State, err: any) {
    state.authenticationStatus = {
      state: "failed",
      message: err.message,
      variant: "danger"
    };
  },
  clearAuthenticationStatus: (state: State) => {
    state.authenticationStatus = null;
  },
  setUserAuthenticated(state: State, user: any) {
    state.user = user;
    state.isAuthenticated = true;
  },
  setUserPermissions(state: State, permissions: any) {
    state.userPermissions = permissions;
  },
  setCurrentUserCompanyId(state: State, companyId: any) {
    state.currentUserCompanyId = companyId;
  },
  setUserToken(state: State, token: any) {
    state.token = token;
  },
  setUserRefreshToken(state: State, refreshToken: any) {
    state.refreshToken = refreshToken;
  },
  clearAuthentication(state: State) {
    state.user = null;
    state.userPermissions = [];
    state.currentUserCompanyId = 0;
    state.isAuthenticated = false;
    state.authenticationStatus = null;
    state.token = "";
    state.refreshToken = "";
    state.partnerId = undefined;
    state.partnerIdentifier = "";
  },
  setLoggedPartnerId(state: State, partnerId: number) {
    state.partnerId = partnerId;
  },
  setLoggedPartnerIdentifier(state: State, partnerIdentifier: string) {
    state.partnerIdentifier = partnerIdentifier;
  }
};
