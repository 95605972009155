import PaymentStatus, { PaymentStatusEnum } from "@/types/payment-status";
import dayjs from "dayjs";
import { nextBusinessDay } from "./hollidays";

export function isDue(dueDateStr: string): boolean {
  const dueDate = dayjs(dueDateStr);
  const now = dayjs();
  return dueDate.isBefore(now, "days");
}

export function getBankSlipCurrentStatus({
  bankSlipDueDate,
  paymentStatus
}: {
  bankSlipDueDate: string | undefined;
  paymentStatus: PaymentStatus | undefined;
}): string {
  if (!bankSlipDueDate || !paymentStatus) return "-";

  if (paymentStatus.name === PaymentStatusEnum.RECEIVED.name) return "Baixado";

  if (paymentStatus.name === PaymentStatusEnum.PAID.name) return "Liquidado";

  const todayDate = dayjs().startOf("d");
  const dueDate = dayjs(bankSlipDueDate).startOf("d");

  if (todayDate.isSameOrBefore(dueDate)) return "Em aberto";
  else if (
    todayDate.isAfter(dueDate) &&
    todayDate.isSameOrBefore(nextBusinessDay(dueDate))
  ) {
    return "Em aberto";
  }

  return "Vencido";
}
