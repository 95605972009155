
import { Component, Prop, Vue, Watch } from "vue-property-decorator";
import { DataOptions, DataTableHeader } from "vuetify";
import BorrowerService from "@/services/borrower-service";
import { format as formatCPF } from "@/utils/cpf";
import formatDate from "@/utils/formatDate";
import DatePicker from "@/components/DatePicker.vue";
import formatDatetime from "../../utils/formatDatetime";
import formatCurrency from "../../utils/formatCurrency";
import SafetyService from "@/services/safety-service";
import DebtAgreementService, {
  ListDebtAgreementsParamsDto,
  ListDebtAgreementsResponseDto
} from "../../services/debt-agreement-service";
import Borrower from "../../types/borrower";
import {
  DebtAgreementStatus,
  DebtAgreementStatusEnum
} from "../../types/debt-agreement-status";
import User from "../../types/user";
import { DebtAgreement } from "../../types/debt-agreement";
import getErrorMessageFromApiError from "@/utils/getErrorMessageFromApiError";
import SelectBorrower from "../core/dashboard/SelectBorrower.vue";

@Component({
  components: {
    DatePicker,
    SelectBorrower
  }
})
export default class DebtAgreementsTable extends Vue {
  @Prop({ default: { data: [], total: 0 } })
  readonly debtAgreements!: ListDebtAgreementsResponseDto;
  @Prop() fetch!: (options: ListDebtAgreementsParamsDto) => Promise<void>;
  @Prop() refresh!: number;

  formatCPF = formatCPF;
  formatDate = formatDate;
  formatDatetime = formatDatetime;
  formatCurrency = formatCurrency;
  DebtAgreementStatusEnum = DebtAgreementStatusEnum;

  debtAgreementService: DebtAgreementService =
    DebtAgreementService.getInstance();
  borrowerService: BorrowerService = BorrowerService.getInstance();
  safetyService: SafetyService = SafetyService.getInstance();

  options: DataOptions;
  headers: Array<DataTableHeader> = [
    { text: "Acordo", value: "id", sortable: false, width: "5%" },
    { text: "Status", value: "status", sortable: false, width: "8%" },
    { text: "Data cadastro", value: "createdAt", sortable: false, width: "9%" },
    {
      text: "Data efetivação",
      value: "effectiveDate",
      sortable: false,
      width: "9%"
    },
    { text: "Pagador", value: "borrower", sortable: false, width: "15%" },
    {
      text: "Valor",
      value: "negotiatedValue",
      sortable: false,
      width: "8%"
    },
    {
      text: "Entrada",
      value: "downPaymentValue",
      sortable: false,
      width: "8%"
    },
    {
      text: "Parcela",
      value: "installmentValue",
      sortable: false,
      width: "8%"
    },
    { text: "Prazo", value: "numInstallments", sortable: false, width: "3%" },
    {
      text: "Data vencimento",
      value: "firstInstallmentDueDate",
      sortable: false,
      width: "10%"
    },
    {
      text: "Contrato",
      value: "loanProposalNumber",
      sortable: false,
      width: "8%"
    },
    { text: "Ações", value: "actions", sortable: false, width: "50px" }
  ];
  loadingXls = false;
  loading: boolean;
  filters: ListDebtAgreementsParamsDto = {
    limit: 10,
    page: 1
  };
  dateFilterTypes: Array<string> = [
    "Data de cadastro",
    "Data de efetivação",
    "Data de vencimento"
  ];
  dateFilterType = "Data de cadastro";
  loadingBorrowers = false;
  borrowers: Borrower[] = [];
  statusList: DebtAgreementStatus[] | null = null;
  responsibleUserList: User[] | null = null;

  constructor() {
    super();
    this.loading = false;
    this.options = {} as DataOptions;
  }

  mounted(): void {
    this.loadFilters();
  }

  @Watch("options")
  onOptionsChange(val: DataOptions): void {
    this.fetchDebtAgreements(val);
  }

  @Watch("dateFilterType")
  onDateFilterTypeChange(): void {
    this.filters.createdAtStart = undefined;
    this.filters.createdAtEnd = undefined;
    this.filters.effectiveDateStart = undefined;
    this.filters.effectiveDateEnd = undefined;
    this.filters.firstInstallmentDueDateStart = undefined;
    this.filters.firstInstallmentDueDateEnd = undefined;
  }

  async loadFilters(): Promise<void> {
    this.fetchDebtAgreementStatusList();
    this.fetchResponsibleUsersList();
  }

  async fetchDebtAgreementStatusList(): Promise<void> {
    const [statusListError, statusList] =
      await this.debtAgreementService.listDebtAgreementStatusList();

    if (!statusListError) {
      this.statusList = statusList;
    } else {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar a lista de status para o filtro."
      });
    }
  }

  async fetchResponsibleUsersList(): Promise<void> {
    const [userListError, userList] = await this.safetyService.listUsers({
      page: 1,
      limit: -1,
      types: ["ADMIN_GOOROO"]
    });

    if (!userListError) {
      this.responsibleUserList = userList!.items;
    } else {
      this.$notify({
        type: "error",
        text: "Não foi possível carregar os usuários responsáveis para o filtro."
      });
    }
  }

  async fetchDebtAgreements(val: DataOptions): Promise<void> {
    const { page, itemsPerPage } = val;

    this.startLoading();
    await this.fetch({
      ...this.filters,
      page: page,
      limit: itemsPerPage,
      loadRelations: false
      // sort: this.handleSort(sortBy, sortDesc),
    });
    this.stopLoading();
  }

  @Watch("refresh")
  onRefreshChange(): void {
    this.options = { ...this.options };
  }

  applyFilter(): void {
    this.options.page = 1;
    this.fetchDebtAgreements(this.options);
  }

  handleSort(
    sortBy: Array<string>,
    sortDesc: Array<boolean>
  ): string | undefined {
    const attr = sortBy[0] ?? "id";
    const order = sortDesc[0] ? "ASC" : "DESC";

    return attr ? `${attr}:${order}` : undefined;
  }

  emitShow(item: DebtAgreement): void {
    this.$emit("show", item);
  }

  emitEdit(item: DebtAgreement): void {
    this.$emit("edit", item);
  }

  async downloadXls(): Promise<void> {
    this.loadingXls = true;
    const [error, data] =
      await this.debtAgreementService.generateDebtAgreementsReportXls(
        this.filters
      );
    if (!error) {
      window.open(
        process.env.VUE_APP_DEBT_AGREEMENT_URL! +
          "/reports/download-xls?token=" +
          data!.token,
        "_blank"
      );
    } else {
      this.$notify({ type: "error", text: getErrorMessageFromApiError(error) });
    }
    this.loadingXls = false;
  }

  emitCancel(item: DebtAgreement): void {
    this.$emit("cancel", item);
  }

  startLoading(): void {
    this.loading = true;
  }

  stopLoading(): void {
    this.loading = false;
  }
}
