export default interface LoanInstallmentStatus {
  id: number;

  name: string;
  readableName: string;

  created_at: Date;
  updated_at: Date;
  deleted_at: Date;
}

const openStatus: LoanInstallmentStatus = {} as LoanInstallmentStatus;
openStatus.id = 1;
openStatus.name = "EM_ABERTO";
openStatus.readableName = "Em aberto";

const paidStatus: LoanInstallmentStatus = {} as LoanInstallmentStatus;
paidStatus.id = 2;
paidStatus.name = "PAGA";
paidStatus.readableName = "Paga";

const notPaidStatus: LoanInstallmentStatus = {} as LoanInstallmentStatus;
notPaidStatus.id = 3;
notPaidStatus.name = "NAO_PAGA";
notPaidStatus.readableName = "Não paga";

const pendingBatchStatus: LoanInstallmentStatus = {} as LoanInstallmentStatus;
pendingBatchStatus.id = 5;
pendingBatchStatus.name = "PENDENTE_LOTE";
pendingBatchStatus.readableName = "Pendente lote";

const pendingSeverancePayStatus: LoanInstallmentStatus =
  {} as LoanInstallmentStatus;
pendingSeverancePayStatus.id = 6;
pendingSeverancePayStatus.name = "PENDENTE_RESCISÃO";
pendingSeverancePayStatus.readableName = "Pendente rescisão";

const pendingBorrowerStatus: LoanInstallmentStatus =
  {} as LoanInstallmentStatus;
pendingBorrowerStatus.id = 7;
pendingBorrowerStatus.name = "PENDENTE_COLABORADOR";
pendingBorrowerStatus.readableName = "Pendente colaborador";

const paidOffStatus: LoanInstallmentStatus = {} as LoanInstallmentStatus;
paidOffStatus.id = 8;
paidOffStatus.name = "LIQUIDADA";
paidOffStatus.readableName = "Liquidada";

export const LoanInstallmentStatusEnum = {
  OPEN: openStatus,
  PAID: paidStatus,
  NOT_PAID: notPaidStatus,
  PENDING_BATCH: pendingBatchStatus,
  PENDING_SEVERANCE_PAY: pendingSeverancePayStatus,
  PENDING_BORROWER: pendingBorrowerStatus,
  PAID_OFF: paidOffStatus
};
